import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from '../contexts/ThemeContext'; // тема

import { PiStarThin, PiStarDuotone } from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogoHeader from './LogoHeader';
import GoBack from './GoBack';
import {
  selectBranchAddress,
  selectBranchCity,
  selectBranchName,
  selectChatiIdFeedback,
  selectColorMainBackground,
  selectFeedbackOptions,
} from '../store/slices/branchSlice';
import { selectPlatforms } from '../store/slices/reviewPlatformSlice';
import Platforms from './Platforms';
import { selectBranchOptions } from '../store/slices/branchOptionsSlice';
import axios from 'axios';
import { selectTableName, selectTableUrl } from '../store/slices/tableUrlSlice';
import { MdRemoveCircle } from 'react-icons/md';

import moment from 'moment';

import CustomFormFeedback from './CustomFormFeedback';
import Cookies from 'js-cookie';


//feedbackOptions 4 = можно ставить 4 и 5, идет перенаправление на карты сразу
//feedbackOptions 5 = можно ставить только 5, идет перенаправление на карты сразу
//feedbackOptions 6 = можно ставить  4 и 5, идет перенаправление на отзыв, а потом на карты
//feedbackOptions 7 = можно ставить  только 5, идет перенаправление на отзыв, а потом на карты
//feedbackOptions 8 = любая оценка, карты отключены

const RatingStars = () => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const branchLink = useSelector((state) => state.branch.link);
  const useCustomForm = useSelector((state) => state.branch.usecustomformfeedback);
  const feedbackOptions = useSelector(selectFeedbackOptions);
  const platforms = useSelector(selectPlatforms);

  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const branchOptions = useSelector(selectBranchOptions);
  const negativeText = branchOptions.negative_feedback_text;

  const [showPlatforms, setShowPlatforms] = useState(false);

  // // // console.log('~ feedbackOptions =', feedbackOptions);
  // // // console.log('~ platforms = ', platforms);

  // // console.log('~ ', rating);

  /// Telegram
  const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
  const chatIdFeedback = useSelector(selectChatiIdFeedback);
  const [inputValueText, setInputValueText] = useState('');
  const [inputCustomValueText, setInputCustomValueText] = useState('');
  const [inputValuePhone, setInputValuePhone] = useState('');
  const [images, setImages] = useState([]);
  const maxImages = 3;

  const branchName = useSelector(selectBranchName);

  const branchCity = useSelector(selectBranchCity);
  const branchAddress = useSelector(selectBranchAddress);
  let tableName = useSelector(selectTableName);
  const tableUrl = useSelector(selectTableUrl);
  /* if (tableName == undefined) {
    tableName = '';
  } */

  const handleInputTextChange = (e) => {
    setInputValueText(e.target.value);
  };
  const handleInputTextPhone = (e) => {
    setInputValuePhone(e.target.value);
  };

  const handlePlatformClick = (platformName) => {

    let reviewsCount = Cookies.get('reviewsCount');
    
    // Если куки не существует, установить начальное значение
    if (!reviewsCount) {
        reviewsCount = 1;
    } else {
        // Увеличение счетчика на 1
        reviewsCount = parseInt(reviewsCount) + 1;
    }
    
    // Сохранение обновленного значения счетчика в куки
    Cookies.set('reviewsCount', reviewsCount, { expires: 365 });
    
    // Формирование сообщения с количеством отзывов
    const messageCount = `Кол-во отзывов от этого клиента: ${reviewsCount}`;
    
    // Здесь ваша логика отправки сообщения в телеграм
    // Например, отправка через axios или fetch
    console.log(messageCount);


    // //console.log("~ Platform clicked:", platformName);
    async function sendMsgGood() {
      let ratingEmoji = '';
      switch (rating) {
        case 1:
          ratingEmoji = '🟤';
          break;
        case 2:
          ratingEmoji = '🔴';
          break;
        case 3:
          ratingEmoji = '🟡';
          break;
        case 4:
          ratingEmoji = '🔵';
          break;
        case 5:
          ratingEmoji = '🟢';
          break;
        default:
          ratingEmoji = '⚪';
          break;
      }

      const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

      const parts = ['🗣️ Отзыв \n', branchName, ', ', branchCity, ', ', branchAddress, ', \n'];

      if (tableName) {
        parts.push(tableName, '\n\n');
      } else {
        parts.push('\n');
      }


      parts.push('Перешел в >> ', platformName);

      parts.push('\n\n' + ratingEmoji + ' Оценка: ' + rating +'\n\n'+  messageCount);

      const sendText = parts.join('');

      return axios
        .post(endpoint, {
          chat_id: chatIdFeedback,
          text: sendText,
        })
        .then((response) => {
          // // console.log('~ Message sent to Telegram:', response.data);
        })
        .catch((error) => {
          console.error('Error sending message to Telegram:', error);
        });
    }

    sendMsgGood();
  };

  async function sendMsg() {
    let ratingEmoji = '';
    switch (rating) {
      case 1:
        ratingEmoji = '🟤';
        break;
      case 2:
        ratingEmoji = '🔴';
        break;
      case 3:
        ratingEmoji = '🟡';
        break;
      case 4:
        ratingEmoji = '🔵';
        break;
      case 5:
        ratingEmoji = '🟢';
        break;
      default:
        ratingEmoji = '⚪';
        break;
    }

    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

    const parts = ['🗣️ Отзыв \n', branchName, ', ', branchCity, ', ', branchAddress, ', \n'];

    if (tableName) {
      parts.push(tableName, '\n\n');
      parts.push(images);
    } else {
      parts.push('\n');
      parts.push(images);
    }

    parts.push(
      inputValueText +
        '\n\n' +
        'телефон: ' +
        (inputValuePhone ? inputValuePhone : '<не оставил>') +
        '\n\n' +
        ratingEmoji +
        ' Оценка: ' +
        rating,
    );

    const sendText = parts.join('');

    return axios
      .post(endpoint, {
        chat_id: chatIdFeedback,
        text: sendText,
      })
      .then((response) => {
        // // console.log('~ Message sent to Telegram:', response.data);
      })
      .catch((error) => {
        console.error('Error sending message to Telegram:', error);
      });
  }

  async function sendMessageOrMediaGroup(TELEGRAM_TOKEN, chatIdFeedback, photoFiles, captionText) {
    // Если нет фото, отправляем только текстовое сообщение
    if (!photoFiles || photoFiles.length === 0) {
      try {
        const response = await axios.post(
          `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`,
          {
            chat_id: chatIdFeedback,
            text: captionText,
          },
        );
        return response.data;
      } catch (error) {
        // ваш код обработки ошибок
      }
    } else {
      // Если есть фото, отправляем как ранее
      return sendMediaGroup(TELEGRAM_TOKEN, chatIdFeedback, photoFiles, captionText);
    }
  }

  async function sendMediaGroup(TELEGRAM_TOKEN, chatIdFeedback, photoFiles, captionText) {
    // // console.log('~ captionText =', captionText);

    // Формируем массив для полей media
    const mediaArray = photoFiles.map((file, index) => ({
      type: 'photo',
      media: `attach://photo${index}`,
      caption: index === 0 ? captionText : undefined,
    }));

    const formData = new FormData();
    formData.append('chat_id', chatIdFeedback);
    formData.append('media', JSON.stringify(mediaArray));

    // Добавляем каждый файл в formData
    photoFiles.forEach((file, index) => {
      formData.append(`photo${index}`, file);
    });

    try {
      const response = await axios.post(
        `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMediaGroup`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    } catch (error) {
      // ваш код обработки ошибок остается прежним
    }
  }

  async function sendPhotoWithCaption(TELEGRAM_TOKEN, chatIdFeedback, photoFile, captionText) {
    // // console.log('~ sendPhotoWithCaption сработала');
    // // console.log('~ TELEGRAM_TOKEN =', TELEGRAM_TOKEN);
    // // console.log('~ chatIdFeedback =', chatIdFeedback);
    // // console.log('~ photoFile =', photoFile);
    // // console.log('~ captionText =', captionText);

    const onePhoto = photoFile[0];

    const formData = new FormData();
    formData.append('chat_id', chatIdFeedback);
    formData.append('photo', onePhoto);
    formData.append('caption', captionText);

    const response = await axios.post(
      `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendPhoto`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  }

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length > maxImages) {
      alert(`Вы можете загрузить максимум ${maxImages} фото`);
      return;
    }
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const renderReview = () => {
    return useCustomForm ? (
      <CustomFormFeedback onCustomFormDataSubmit={handleCustomFormDataSubmit} />
    ) : (
      <div className="div-ratingstars-9 flex-col items-center w-full sm:w-96 pl-4 pr-4">
        <div
          className="div-ratingstars-10"
          style={{
            fontFamily: colors.fontForFeedback,
            fontSize: `${colors.fontForFeedbackSize}px`,
            fontWeight: `${colors.fontForFeedbackWeight}`,
            fontStyle: `${colors.fontForFeedbackStyle}`,
          }}>
          <p
            style={{ color: colors.colorFontFeedback }}
            className="p-ratingstars-11 py-4 font-normal text-sm">
            <span> {negativeText}</span>
          </p>
        </div>
        <div className="div-ratingstars-12 w-full">
          <textarea
            style={{
              backgroundColor: colors.colorEditsFeedback,
              borderColor: colors.colorEditsBorderFeedback,
              borderRadius: `${colors.allInputsBorderRadius}px`,
              color: colors.colorFontInputFeedback,
            }}
            name=""
            id=""
            rows="8"
            className="textarea-ratingstars-13 w-full    py-2 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-sm shadow focus:outline-none focus:shadow-outline"
            placeholder="Напишите ваш отзыв..."
            onChange={handleInputTextChange}></textarea>
        </div>

        <div
          style={{
            fontFamily: colors.fontForFeedback,
            fontSize: `${colors.fontForFeedbackSize}px`,
            fontWeight: `${colors.fontForFeedbackWeight}`,
            fontStyle: `${colors.fontForFeedbackStyle}`,
          }}>
          <p
            style={{ color: colors.colorFontFeedback }}
            className="p-ratingstars-15 div-ratingstars-14 font-thin text-sm">
            <span>Можно отправить до 3х фото. </span>
          </p>
        </div>
        <div className="div-ratingstars-16">
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            className="input-ratingstars-17 text-white "
          />
        </div>
        <div className="div-ratingstars-18 flex flex-row">
          {images.map((image, index) => (
            <div key={index} className="div-ratingstars-19 relative mt-3 mr-2">
              <img
                className="img-ratingstars-20"
                src={URL.createObjectURL(image)}
                alt={`img-${index}`}
                width={100}
                height={100}
              />
              <button
                className="btn btn-ratingstars-20 absolute top-0 right-0 bg-slate-600 text-white rounded-full w-4 h-4 flex justify-center items-center"
                onClick={() => handleRemoveImage(index)}>
                <MdRemoveCircle className="div-ratingstars-MdRemoveCircle-21" />
              </button>
            </div>
          ))}
        </div>

        <div className="div-ratingstars-22 w-full">
          <input
            style={{
              backgroundColor: colors.colorEditsFeedback,
              borderColor: colors.colorEditsBorderFeedback,
              borderRadius: `${colors.allInputsBorderRadius}px`,
              color: colors.colorFontInputFeedback,
            }}
            onChange={handleInputTextPhone}
            placeholder="Телефон для связи (по желанию)"
            className="input-ratingstars-tel-23 my-4 w-full  py-1 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-sm shadow focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          style={{
            backgroundColor: colors.colorButtonFeedback,
            borderColor: colors.colorButtonBorderFeedback,
            borderWidth: '1px',
            color: colors.colorButtonTextFeedback,
            borderRadius: `${colors.allButtonsBorderRadius}px`,
          }}
          onClick={() => handleSendMessage(inputValueText)}
          className="btn btn-ratingstars-submit-23 mt-4 mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Отправить
        </button>
      </div>
    );
  };

  async function handleSendMessage(sendingText) {

    let reviewsCount = Cookies.get('reviewsCount');
    
    // Если куки не существует, установить начальное значение
    if (!reviewsCount) {
        reviewsCount = 1;
    } else {
        // Увеличение счетчика на 1
        reviewsCount = parseInt(reviewsCount) + 1;
    }
    
    // Сохранение обновленного значения счетчика в куки
    Cookies.set('reviewsCount', reviewsCount, { expires: 365 });
    
    // Формирование сообщения с количеством отзывов
    const messageCount = `Кол-во отзывов от этого клиента: ${reviewsCount}`;
    
    // Здесь ваша логика отправки сообщения в телеграм
    // Например, отправка через axios или fetch
    console.log(messageCount);


    let ratingEmoji = '';
    //формируем оценку
    switch (rating) {
      case 1:
        ratingEmoji = '🟤';
        break;
      case 2:
        ratingEmoji = '🔴';
        break;
      case 3:
        ratingEmoji = '🟡';
        break;
      case 4:
        ratingEmoji = '🔵';
        break;
      case 5:
        ratingEmoji = '🟢';
        break;
      default:
        ratingEmoji = '⚪';
        break;
    }

    // формируем текстовое сообщение

    const parts = ['🗣️ Отзыв \n', branchName, ', ', branchCity, ', ', branchAddress, ', \n'];

    if (tableName) {
      parts.push(tableName, '\n\n');
    } else {
      parts.push('\n');
    }

    // // console.log('~ 🧨  sendingText = ', sendingText);

    parts.push(
      sendingText +
        '\n\n' +
        'телефон: ' +
        (inputValuePhone ? inputValuePhone : '<не оставил>') +
        '\n\n' +
        ratingEmoji +
        ' Оценка: ' +
        rating, '\n\n' +
        messageCount
    );

    const sendText = parts.join('');
    // console.log('~ inputValuePhone = ', inputValuePhone);

    sendMessageOrMediaGroup(TELEGRAM_TOKEN, chatIdFeedback, images, sendText);

    // После отправки сообщения
    // После отправки сообщения
    if (feedbackOptions === 8) {
      navigate('/feedbackthanks');
    } else {
      // Обработка для других feedbackOptions
      if ((feedbackOptions === 6 && rating >= 4) || (feedbackOptions === 7 && rating === 5)) {
        setShowPlatforms(true);
      } else {
        navigate('/feedbackthanks');
      }
    }

    // отправляем отзыв на сервер для сохранения
    sendReviewToServer(sendText);

    //END handleSendMessage
  }

  const objectToText = (data) => {
    // Преобразуем каждую пару ключ-значение в строку и соединяем их в одну большую строку
    return Object.entries(data)
      .map(([key, value]) => {
        return `${key}: ${value}`;
      })
      .join('\n'); // Разделяем пары ключ-значение переносом строки для удобства чтения
  };

  const handleCustomFormDataSubmit = (dataWithTitle) => {
    const text = objectToText(dataWithTitle);
    setInputCustomValueText(text);
    //handleSendCustomFormMessage();
  };

  useEffect(() => {
    if (inputCustomValueText) {
      // Проверяем, не пустое ли значение, чтобы не отправлять пустые сообщения
      console.log('~ 🚲 useEffect');
      handleSendCustomFormMessage();
    }
  }, [inputCustomValueText]);

  async function handleSendCustomFormMessage() {
    // console.log('~ handle chatIdFeedback = ', chatIdFeedback);
    let ratingEmoji = '';
    //формируем оценку
    switch (rating) {
      case 1:
        ratingEmoji = '🟤';
        break;
      case 2:
        ratingEmoji = '🔴';
        break;
      case 3:
        ratingEmoji = '🟡';
        break;
      case 4:
        ratingEmoji = '🔵';
        break;
      case 5:
        ratingEmoji = '🟢';
        break;
      default:
        ratingEmoji = '⚪';
        break;
    }

    // формируем текстовое сообщение

    const parts = ['🗣️ Отзыв \n', branchName, ', ', branchCity, ', ', branchAddress, ', \n'];

    if (tableName) {
      parts.push(tableName, '\n\n');
    } else {
      parts.push('\n');
    }

    // // console.log('~ 🧨  sendingText = ', sendingText);

    parts.push(
      inputCustomValueText +
        '\n\n' +
        'телефон: ' +
        (inputValuePhone ? inputValuePhone : '<не оставил>') +
        '\n\n' +
        ratingEmoji +
        ' Оценка: ' +
        rating,
    );

    const sendText = parts.join('');
    // console.log('~ inputValuePhone = ', inputValuePhone);

    sendMessageOrMediaGroup(TELEGRAM_TOKEN, chatIdFeedback, images, sendText);

    // После отправки сообщения
    // После отправки сообщения
    if (feedbackOptions === 8) {
      navigate('/feedbackthanks');
    } else {
      // Обработка для других feedbackOptions
      if ((feedbackOptions === 6 && rating >= 4) || (feedbackOptions === 7 && rating === 5)) {
        setShowPlatforms(true);
      } else {
        navigate('/feedbackthanks');
      }
    }

    // отправляем отзыв на сервер для сохранения
    sendCustomReviewToServer(inputCustomValueText);

    // END handleSendCustomFormMessage
  }










  const sendCustomReviewToServer = async () => {
    //console.log(`~ 🚩 = `, inputCustomValueText);
    // Получаем текущую дату и время в формате ISO с учетом локальной временной зоны
    const clientDateTime = moment().local().format('YYYY-MM-DD HH:mm:ss');
    console.log('clientDateTime: ', clientDateTime);
    // Подготавливаем данные для отправки
    const dataToSend = {
      inputCustomValueText,
      branchLink,
      rating,
      tableUrl,
      clientDateTime,
      // Здесь можно добавить дополнительные данные, если это необходимо
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/postCustomReview/${branchLink}`,
        dataToSend, // Отправляем объект данных напрямую
        {
          headers: {
            'Content-Type': 'application/json', // Указываем, что отправляем JSON
          },
        },
      );
      console.log('Ответ сервера: ', response.data);
      return response.data; // Предполагается, что сервер возвращает данные после обработки
    } catch (error) {
      console.error('Ошибка при отправке на сервер: ', error);
    }
  };

  const sendReviewToServer = async () => {
    // Получаем текущую дату и время в формате ISO с учетом локальной временной зоны
    const clientDateTime = moment().local().format('YYYY-MM-DD HH:mm:ss');
    console.log('clientDateTime: ', clientDateTime);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/postReview`, {
        inputValueText,
        branchLink,
        rating,
        tableUrl,
        inputValuePhone,
        clientDateTime,
        // Здесь можете добавить дополнительные данные, если это необходимо
      });
      return response.data; // Предполагается, что сервер возвращает данные созданной группы историй
    } catch (error) {}
  };

  function renderContent() {
    // Проверка для состояния 1
    if (showPlatforms) {
      return <Platforms reviewText={inputValueText} onPlatformClick={handlePlatformClick} />;
    }

    // если оценка еще не поставлена
    if (rating <= 0) {
      return (
        <div
          className="div-rating-stars-2"
          style={{
            fontFamily: colors.fontForFeedback,
            fontSize: `${colors.fontForFeedbackSize}px`,
            fontWeight: `${colors.fontForFeedbackWeight}`,
            fontStyle: `${colors.fontForFeedbackStyle}`,
          }}>
          {/* Код для оценки еще не поставленной */}
          <div className="div-rating-stars-3">
            <div
              style={{ color: colors.colorFontFeedback }}
              className="div-rating-stars-4 text-center py-5 text-slate-600">
              <span> Оцените нашу работу</span>
            </div>
            <div
              style={{
                backgroundColor: colors.colorDivFeedback,
                borderColor: colors.colorDivBorderFeedback,
              }}
              className="div-rating-stars-5 shadow-md h-20 p-1 border border-gray-200 rounded inline-flex items-center">
              {[...Array(5)].map((_, i) => {
                return (
                  <div className="div-rating-stars-6" key={i} onClick={() => setRating(i + 1)}>
                    {i < rating ? (
                      <PiStarDuotone
                        style={{ fontSize: '3rem', color: 'gold', strokeWidth: '1' }}
                        className="div-rating-PiStarDuotone-7"
                      />
                    ) : (
                      <PiStarThin
                        className="div-rating-PiStarThin-8"
                        style={{ color: colors.colorStarsFeedback, fontSize: '3rem' }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    // Оценка поставлена. Хорошая. feedbackOptions 4,5
    // Оценка поставлена. feedbackOptions 4,5

    // Проверка для плохой оценки (например, 3 и ниже)
    if (rating <= 3) {
      return renderReview();
    }

    // Обработка feedbackOptions 4, 5, 6, 7
    switch (feedbackOptions) {
      case 4:
        if (rating >= 4) {
          return <Platforms onPlatformClick={handlePlatformClick} />;
        }
        break;
      case 5:
        if (rating === 5) {
          return <Platforms onPlatformClick={handlePlatformClick} />;
        }
        break;
      case 6:
        if (rating >= 4) {
          return renderReview();
        }
        break;
      case 7:
        if (rating === 5) {
          return renderReview();
        }
        break;
      case 8:
        // Всегда показываем textarea, затем переходим на страницу благодарности
        return renderReview();
      // Добавьте здесь дополнительные обработки для новых feedbackOptions
      default:
        break;
    }

    // Если предыдущие условия не выполнились, отображаем renderReview()
    return renderReview();
  }

  return (
    <div className={`  `}>
      <div className="div-render-content-1 flex flex-col items-center">{renderContent()}</div>
    </div>
  );
};

export default RatingStars;
