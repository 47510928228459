import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, selectCart, selectTotalPrice } from '../store/slices/cartSlice';
import { ThemeContext } from '../contexts/ThemeContext';
import { selectCartDelivery, selectTotalPriceDelivery } from '../store/slices/cartDeliverySlice';
import axios from 'axios';
import {
  selectChatiIdOrder,
  selectUseOrderComment,
  useordercomment,
} from '../store/slices/branchSlice';
import { selectTableName } from '../store/slices/tableUrlSlice';

const ShowOrder = ({
  onClose,
  isDelivery,
  colorMainBackground,
  colorFontBranchName,
  colorSearch,
  allInputsBorderRadius,
  allButtonsBorderRadius,
  colorOrderDivButton,
  colorOrderDivFontColorButton,
  colorOrderDivButtonBorder, 
  colorFontSearchInput

}) => {
  const cart = useSelector(selectCart);
  // console.log('~ cart = ', cart);
  const dispatch = useDispatch();
  const [commentOrder, setCommentOrder] = useState('');

  const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
  const chatIdOrder = useSelector(selectChatiIdOrder);
  let tableName = useSelector(selectTableName);
  const useordercomment = useSelector(selectUseOrderComment);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const totalPrice = useSelector((state) => {
    const slice = isDelivery ? selectTotalPriceDelivery(state) : selectTotalPrice(state);
    return slice;
  });

  function sendMsgGood(order, isDelivery) {
    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

    let sendText;
    let chatId = chatIdOrder;

    sendText =
      '📍 Заказ \n' +
      tableName +
      '\n\n' +
      order +
      '\n\n' +
      'Комментарий к заказу: ' +
      '\n' +
      commentOrder +
      '\n' +
      'Имя: ' + name +
      '\nТелефон: ' + phone;

    return axios
      .post(endpoint, {
        chat_id: chatId,
        text: sendText,
      })
      .then((response) => {
        // console.log('~ Message sent to Telegram:', response.data);
        dispatch(clearCart());
      })
      .catch((error) => {
        console.error('Error sending message to Telegram:', error);
      });
  }

  let cartLocal = useSelector(selectCart);
  let cartDelivery = useSelector(selectCartDelivery);

  if (isDelivery) {
    // console.log('~ is Delivery order = ', cartDelivery);
  }

  function sendOrder() {
    let order;
    if (isDelivery) {
      order = cartDelivery.map((item) => `${item.title}: ${item.quantity}`).join('\n');
    } else {
      order = cartLocal.map((item) => `${item.title}: ${item.quantity}`).join('\n');
    }
    if (useordercomment === true && (!name || !phone)) {
      alert(`Имя и телефон обязательны для заполнения!`);
      return;
    }

    sendMsgGood(order, isDelivery);
  }

  



  return (
    <>
      <div className="div-showorder-1 fixed inset-0 bg-black opacity-70 z-40 "></div>
      <div className='div-showorder-2'>
        <div
          style={{
            backgroundColor: colorMainBackground,
            color: colorFontBranchName,
          }}
          className="div-showorder-3 max-h-[80%] w-11/12 md:w-8/12 lg:w-2/5 overflow-y-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white p-5 rounded shadow-lg">
          <button onClick={onClose} className="btn-showorder-4 absolute top-1 right-1 text-2xl font-bold">
            &times;
          </button>
          <h2 className="h-showorder-5 text-xl mb-4">Ваш заказ:</h2>
          <table className="table-showorder-6 min-w-full">
            <tbody className="tbody-showorder-7 text-sm">
              {cart.map((item, index) => (
                <tr key={index} className='tr-showorder-8'>
                  <td className="td-showorder-9 border px-4 py-2"><span className='span-showorder-11'> {item.title}</span></td>
                  <td className="td-showorder-10 border px-4 py-2"> <span className='span-showorder-12'> {item.quantity}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="div-showorder-13 pt-4 font-medium">
            Итого на сумму: {parseFloat(totalPrice.toFixed(2))} руб.
          </div>

          {useordercomment && (
            <>
              <input
                style={{
                  backgroundColor: colorSearch,
                  borderRadius: `${allInputsBorderRadius}px`,
                  color: colorFontSearchInput,
                }}
                type="text"
                placeholder="Имя"
                className="input-showorder-14 mt-4 border border-slate-400 w-full sm:w-2/3 md:w-2/3 pl-2 py-1"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                style={{
                  backgroundColor: colorSearch,
                  borderRadius: `${allInputsBorderRadius}px`,
                  color: colorFontSearchInput,
                }}
                type="text"
                placeholder="Телефон"
                className="input-showorder-15 mt-4 border border-slate-400 w-full sm:w-2/3 md:w-2/3 pl-2 py-1"
                onChange={(e) => setPhone(e.target.value)}
              />
            </>
          )}

          <textarea
            style={{
              backgroundColor: colorSearch,
              borderRadius: `${allInputsBorderRadius}px`,
              color: colorFontSearchInput,
            }}
            rows={3}
            type="text"
            placeholder="Комментарий к заказу"
            className="textarea-showorder-14 mt-4 border  border-slate-400 w-full sm:w-2/3 md:w-2/3 pl-2 py-1"
            onChange={(e) => setCommentOrder(e.target.value)}
          />



          <div className="div-showorder-14 flex justify-end">
            <button 
            
              style={{
                borderRadius: `${allButtonsBorderRadius}px`,
                backgroundColor: colorOrderDivButton,
                color: colorOrderDivFontColorButton,
                borderColor: colorOrderDivButtonBorder,

              }}
            onClick={sendOrder} className="btn btn-showorder-15 bg-slate-400 border px-4 py-1 rounded mt-4">
            <span>  Заказать</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowOrder;

