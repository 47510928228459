import { createSlice } from '@reduxjs/toolkit';

// Инициализация начального состояния
const initialState = {
    cardViewVariant: 1, // Вы можете задать начальное значение по умолчанию
};

// Создание слайса
const viewVariantSlice = createSlice({
    name: 'viewVariant',
    initialState,
    reducers: {
        setCardViewVariant: (state, action) => {
            state.cardViewVariant = action.payload;
        }
    }
});

// Экспорт экшенов
export const { setCardViewVariant } = viewVariantSlice.actions;

// Экспорт редьюсера
export default viewVariantSlice.reducer;
