import React from 'react';

const Unavailable = ({ text }) => {
  return (
    <>
      <div className="text-center pt-6">
        <div >
          <p>{text}</p>
        </div>
        <div className="pt-6 ">
          Сервис{' '}
          <a href="http://restolink.ru/" className="text-blue-500">
            RestoLink.ru
          </a>{' '}
        </div>
      </div>
    </>
  );
};

export default Unavailable;
