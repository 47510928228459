import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../contexts/ThemeContext'; // тема

import { LiaStar } from 'react-icons/lia';

import { CiCreditCard1, CiDeliveryTruck } from 'react-icons/ci';
import { PiCoinsLight } from 'react-icons/pi';
import { BiDish } from 'react-icons/bi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { selectBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import { useSelector } from 'react-redux';
import {
  selectColorMainBackground,
  selectGoToFeedback,
  selectUseDelivery,
  selectUseFeedback,
  selectUseMenu,
  selectUsePayment,
  selectUseTips,
} from '../store/slices/branchSlice';
import DirectFeedbackPage from '../pages/DirectFeedbackPage';

const TopCard = ({ pageName, bgColor }) => {
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const branchLinkGlobal = useSelector(selectBranchLinkGlobal);

  const useMenu = useSelector(selectUseMenu);
  const useFeedback = useSelector(selectUseFeedback);
  const usePayment = useSelector(selectUsePayment);
  const useTips = useSelector(selectUseTips);
  const useDelivery = useSelector(selectUseDelivery);

  const features = [useMenu, useFeedback, usePayment, useTips, useDelivery];
  const activeFeaturesCount = features.filter(Boolean).length;



  /*   useEffect(() => {
    // console.log('~ TOP branchLinkGlobal =', branchLinkGlobal);
  }, []);
 */
  /* text-md sm:text-sm md:text-base lg:text-lg */

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableUrl = queryParams.get('t');



/*   if (goToFeedback?.goToFeedback) {
    return <DirectFeedbackPage></DirectFeedbackPage>;
  } */

  return (
    <>
      {activeFeaturesCount > 0 && (
        <div
          style={{
            backgroundColor: bgColor,
            color: colors.colorFontMainMenu,
            borderColor: colors.colorBorderMainMenu,
            fontFamily: colors.fontForMainMenu,
            fontSize: `${colors.fontForMainMenuSize}px`,
            fontWeight: `${colors.fontForMainMenuWeight}`,
            //fontStyle: `${colors.fontForMainMenuStyle}`
            fontStyle: 'normal',
            borderRadius: `${colors.mainMenuBorderRadius}px`,
            borderWidth: `${colors.mainMenuBorderSize}px`,
            //mainMenuBorderSize
          }}
          className={`div-topcard-1 main-menu flex-wrap  inline-flex -space-x-px overflow-hidden   shadow-sm  text-xs sm:text-sm md:text-sm lg:text-sm `}>
          <>
            {useMenu && (
              <div className="div-topcard-menu-2 flex flex-col items-center justify-center px-2 py-2 ">
                <Link
                  to={`/${branchLinkGlobal}${tableUrl ? `?t=${tableUrl}` : ''}`}
                  className={`link-topcard-3 flex flex-col items-center ${
                    pageName === 'menu' ? 'font-semibold' : ''
                  }`}
                  style={
                    pageName === 'menu'
                      ? {
                          textDecorationLine: 'underline',
                          color: colors.colorFontMainMenuActive, // Исправлено на colorFontMainMenuActive для текста
                        }
                      : {}
                  }>
                  <BiDish
                    className="icon-topcard-BiDish-8 items-center"
                    // Изменяем стиль иконки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'menu'
                          ? colors.colorFontIconsMainMenuActive
                          : colors.colorFontIconsMainMenu,
                    }}
                  />
                  <button
                    className="btn-topcard-menu-13 inline-flex"
                    // Изменяем стиль текста кнопки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'menu'
                          ? colors.colorFontMainMenuActive
                          : colors.colorFontMainMenu,
                      fontStyle: `${colors.fontForMainMenuStyle}`,
                    }}>
                    <span> Меню</span>
                  </button>
                </Link>
              </div>
            )}
            {useFeedback && (
              <div className="div-topcard-feedback-17 flex flex-col items-center justify-center px-2 py-2 ">
                <Link
                  to={`/${branchLinkGlobal}/feedback/${tableUrl ? `?t=${tableUrl}` : ''}`}
                  className={`link-topcard-4 flex flex-col items-center ${
                    pageName === 'feedback' ? 'font-semibold' : ''
                  }`}
                  style={
                    pageName === 'feedback'
                      ? { textDecorationLine: 'underline', color: colors.colorFontMainMenuActive }
                      : {}
                  }>
                  <LiaStar
                    className="icon-topcard-liastar-9  items-center"
                    // Изменяем стиль иконки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'feedback'
                          ? colors.colorFontIconsMainMenuActive
                          : colors.colorFontIconsMainMenu,
                    }}
                  />
                  <button
                    className="btn-topcard-feedback-14 inline-flex"
                    // Изменяем стиль текста кнопки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'feedback'
                          ? colors.colorFontMainMenuActive
                          : colors.colorFontMainMenu,
                      fontStyle: `${colors.fontForMainMenuStyle}`,
                    }}>
                    <span> Отзыв</span>
                  </button>
                </Link>
              </div>
            )}
            {usePayment && (
              <div className="div-topcard-payment-18 flex flex-col items-center justify-center px-2 py-2">
                <Link
                  to={`/${branchLinkGlobal}/payment/${tableUrl ? `?t=${tableUrl}` : ''}`}
                  className={`link-topcard-5 flex flex-col items-center ${
                    pageName === 'payment' ? 'font-semibold' : ''
                  }`}
                  style={
                    pageName === 'payment'
                      ? {
                          textDecorationLine: 'underline',
                          color: colors.colorFontMainMenuActive, // Применяем активный цвет текста
                        }
                      : {}
                  }>
                  <CiCreditCard1
                    className="icon-topcard-CiCreditCard1-10 items-center"
                    // Изменяем стиль иконки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'payment'
                          ? colors.colorFontIconsMainMenuActive
                          : colors.colorFontIconsMainMenu,
                    }}
                  />
                  <button
                    className="btn-topcard-payment-14 inline-flex"
                    // Изменяем стиль текста кнопки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'payment'
                          ? colors.colorFontMainMenuActive
                          : colors.colorFontMainMenu,
                      fontStyle: `${colors.fontForMainMenuStyle}`,
                    }}>
                    <span> Оплата</span>
                  </button>
                </Link>
              </div>
            )}
            {useTips && (
              <div className="div-topcard-tips-19 flex flex-col items-center justify-center px-2 py-2 ">
                <Link
                  to={`/${branchLinkGlobal}/tips/${tableUrl ? `?t=${tableUrl}` : ''}`}
                  className={`link-topcard-6 flex flex-col items-center ${
                    pageName === 'tips' ? 'font-semibold' : ''
                  }`}
                  style={
                    pageName === 'tips'
                      ? {
                          textDecorationLine: 'underline',
                          color: colors.colorFontMainMenuActive, // Применяем активный цвет текста
                        }
                      : {}
                  }>
                  <PiCoinsLight
                    className="icon-topcard-PiCoinsLight-11 items-center"
                    // Изменяем стиль иконки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'tips'
                          ? colors.colorFontIconsMainMenuActive
                          : colors.colorFontIconsMainMenu,
                    }}
                  />
                  <button
                    className="btn-topcard-tips-15 inline-flex"
                    // Изменяем стиль текста кнопки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'tips'
                          ? colors.colorFontMainMenuActive
                          : colors.colorFontMainMenu,
                      fontStyle: `${colors.fontForMainMenuStyle}`,
                    }}>
                    <span> Чаевые</span>
                  </button>
                </Link>
              </div>
            )}
            {useDelivery && (
              <div className="div-topcard-delivery-20 flex flex-col items-center justify-center px-2 py-2">
                <Link
                  to={`/${branchLinkGlobal}/delivery/${tableUrl ? `?t=${tableUrl}` : ''}`}
                  className={`link-topcard-7 flex flex-col items-center ${
                    pageName === 'delivery' ? 'font-semibold' : ''
                  }`}
                  style={
                    pageName === 'delivery'
                      ? {
                          textDecorationLine: 'underline',
                          color: colors.colorFontMainMenuActive, // Применяем активный цвет текста
                        }
                      : {}
                  }>
                  <CiDeliveryTruck
                    className="icon-topcard-CiDeliveryTruck-12 items-center"
                    // Изменяем стиль иконки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'delivery'
                          ? colors.colorFontIconsMainMenuActive
                          : colors.colorFontIconsMainMenu,
                    }}
                  />
                  <button
                    className="btn-topcard-delivery-16 inline-flex"
                    // Изменяем стиль текста кнопки в зависимости от активности страницы
                    style={{
                      color:
                        pageName === 'delivery'
                          ? colors.colorFontMainMenuActive
                          : colors.colorFontMainMenu,
                      fontStyle: `${colors.fontForMainMenuStyle}`,
                    }}>
                    <span> Доставка</span>
                  </button>
                </Link>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default TopCard;
