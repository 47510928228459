import React from 'react';

// StoryPreview.js
function StoryPreview({
  content,
  profileImage,
  onClick,
  colorStoriesGroupBorder,
  groupStoriesWidth,
  groupStoriesHeight,
  groupStoriesBorderRadius,
  groupStoriesBorderRadiusSize
}) {
 
  return (
    <div
      className="story-preview"
      onClick={onClick}
      style={{
        borderRadius: `${groupStoriesBorderRadius}px`,
        overflow: 'hidden',
        height: `${groupStoriesHeight}px`,
        width: `${groupStoriesWidth}px`,
        borderColor: colorStoriesGroupBorder,
        borderWidth: `${groupStoriesBorderRadiusSize}px`
      }}>
      <img
      className='img-story-preview-1'
        src={`${process.env.REACT_APP_URL}${profileImage}`} // Адрес изображения предпросмотра
        alt="preview"
        style={{
          width: `${groupStoriesWidth}px`,
          height: `${groupStoriesHeight}px`,
          objectFit: 'cover',
          borderRadius: `${groupStoriesBorderRadius}px`,
        }}
      />
    </div>
  );
}

export default StoryPreview;
