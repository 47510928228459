import React, { useRef } from 'react';
import Form from '@rjsf/core';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import validator from '@rjsf/validator-ajv8';
import { useSelector } from 'react-redux';


const CustomFormFeedback = ({ onCustomFormDataSubmit }) => {
  const formWrapperRef = useRef(null); // Создаем ref для формы

  // Инициализация AJV
  const ajv = new Ajv({
    allErrors: true,
    useDefaults: true,
  });
  addFormats(ajv);

  // Твои schema и uiSchema

  const schema = useSelector((state) => state.branch.customformfeedback.schema);
  const uiSchema = useSelector((state) => state.branch.customformfeedback.uiSchema);

  const styles = {
    '.form-group': {
      display: 'flex;',
      flexDirection: 'column;',
      justifyContent: 'flex-start',
    },

    '.field': {
      display: 'flex;',
      flexDirection: 'column;',
    },
    '.field-array': {
      paddingTop: '20px;',
    },
    '.control-label': {
      paddingTop: '5px;',
    },
    '.btn-info': {
      visibility: 'hidden', // Изменено для скрытия без удаления из потока документа
      position: 'absolute', // Опционально, для исключения из потока, если нужно
      height: '1px', // Уменьшаем до минимального размера
      width: '1px', // Уменьшаем до минимального размера */
    },
    span: {
      paddingLeft: '5px',
    },
    '.div-render-content-1': {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    '.form-control': {
      border: '1px solid black',
    },
    'textarea': {
      paddingLeft: '4px',
    },
    'legend': {
      fontSize: '18px',
      paddingBottom: '15px',
      fontWeight : '600'
    },
    
  };

  const onSubmit = ({ formData }) => {
    // Объект schema - это ваша JSON Schema, которую вы используете для формы
    const titles = Object.keys(schema.properties).reduce((acc, key) => {
      acc[key] = schema.properties[key].title; // Убедитесь, что здесь используется title, а не titleReport
      return acc;
    }, {});

    // Сопоставляем значения с их titles
    const dataWithTitle = Object.keys(formData).reduce((acc, key) => {
      const title = titles[key];

      // Если мы работаем с categories и есть другое значение, объединяем их
      if (key === 'categories' && formData['otherCategory']) {
        acc[title] = [...formData[key], formData['otherCategory']];
      } else {
        acc[title] = formData[key];
      }
      return acc;
    }, {});

    // Теперь в dataWithTitle для 'categories' будут объединены выбранные категории и 'Другое', если оно было введено
    onCustomFormDataSubmit(dataWithTitle);
    console.log('Data with titles: ', dataWithTitle);
  };

  /*   const onSubmit = ({ formData }) => {
    // Объект schema - это твоя JSON Schema, которую ты используешь для формы
    const titles = Object.keys(schema.properties).reduce((acc, key) => {
      acc[key] = schema.properties[key].titleReport;
      return acc;
    }, {});

    // Сопоставляем значения с их titles
    const dataWithTitle = Object.keys(formData).reduce((acc, key) => {
      const title = titles[key];
      acc[title] = formData[key];
      return acc;
    }, {});

    //const jsonData = JSON.stringify(dataWithTitle);


    onCustomFormDataSubmit(dataWithTitle);
    console.log('Data with titles: ', dataWithTitle);
  };
 */
  const handleSubmit = () => {
    if (formWrapperRef.current) {
      // Находим скрытую кнопку сабмита и эмулируем клик
      const submitButton = formWrapperRef.current.querySelector('.btn-info');
      if (submitButton) {
        submitButton.click();
      }
    }
  };
  // Функция для преобразования объекта стилей в строку для внедрения в style тег
  const convertStylesToString = (styles) => {
    return Object.entries(styles)
      .map(([selector, style]) => {
        const styleString = Object.entries(style)
          .map(([prop, value]) => {
            const formattedProp = prop.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
            return `${formattedProp}: ${value};`;
          })
          .join(' ');
        return `${selector} { ${styleString} }`;
      })
      .join(' ');
  };

  //const validator = createValidator();

  return (
    <div className="mt-6">
      <style>{convertStylesToString(styles)}</style>
      <div ref={formWrapperRef}>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={onSubmit}
          validator={validator} // Передаем сюда созданный валидатор
        />
      </div>
      <button
        onClick={handleSubmit}
        className="btn btn-primary border-2 p-2 bg-slate-100 rounded-md mt-6">
        Отправить
      </button>
    </div>
  );
};

export default CustomFormFeedback;
