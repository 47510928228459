import { createSlice } from '@reduxjs/toolkit';

const cartDeliverySlice = createSlice({
  name: 'cartDelivery',
  initialState: {
    items: []
  },
  reducers: {
    addToCartDelivery: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id);
      
      if (index !== -1) {
        state.items[index].quantity += 1;
      } else {
        state.items.push(action.payload);
      }
    },
    removeFromCartDelivery: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id);
     
      if (index !== -1) {
        if (state.items[index].quantity > 1) {
          state.items[index].quantity -= 1;
        } else {
          state.items.splice(index, 1);
        }
      }
    },
    clearCartDelivery: (state) => {
      state.items = [];
    },
    loadCartFromLocalStorage: (state, action) => {
      state.items = action.payload;
    },
  }
});

export const { addToCartDelivery, removeFromCartDelivery, clearCartDelivery, loadCartFromLocalStorage  } = cartDeliverySlice.actions;

export const selectTotalQuantityDelivery = (state) => 
  state.cartDelivery.items.reduce((total, item) => total + item.quantity, 0);

export const selectTotalPriceDelivery = (state) => 
  state.cartDelivery.items.reduce((total, item) => total + item.price * item.quantity, 0);

export const selectCartDelivery = (state) => state.cartDelivery.items;

export default cartDeliverySlice.reducer;
