import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import LogoHeader from '../Components/LogoHeader';
import Header from '../Components/Header';
import { useSelector } from 'react-redux';
import { selectBranchOrderThanks, selectColorMainBackground } from '../store/slices/branchSlice';

const DeliveryEndPage = () => {
  const orderThanks = useSelector(selectBranchOrderThanks);
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  return (
    <div className='div-dep-1' style={{ backgroundColor: colors.colorMainBackground }}>
      <LogoHeader />
      <Header />
      <div className={`div-dep-2 h-screen pt-6  text-center `}>
        <div className='div-dep-3'>
          <p style={{ color: colors.colorFontBranchName }} className='div-dep-3'><span className='span-dep-4'> {orderThanks}</span></p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryEndPage;
