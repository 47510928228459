// features/customCssSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Асинхронный thunk для загрузки CSS
export const fetchCustomCss = createAsyncThunk('customCss/fetch', async () => {
  const response = await fetch('/api/path-to-custom-css');
  const css = await response.text();
  return css;
});

const customCssSlice = createSlice({
  name: 'customCss',
  initialState: {
    css: '', // начальное состояние CSS пустое
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomCss.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomCss.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.css = action.payload;
      })
      .addCase(fetchCustomCss.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default customCssSlice.reducer;
