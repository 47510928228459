import React, { useEffect, useRef } from 'react';

const TestPage = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  function goRef4() {
    // console.log('~ нажал 4');
    // console.log('~ ', ref4.current);
    if (ref4.current) {
      ref4.current.focus();
    }
  }

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <p onClick={() => scrollToRef(ref1)}>ссылка 1</p>
      <p onClick={() => scrollToRef(ref2)}>ссылка 2</p>
      <p onClick={() => scrollToRef(ref3)}>ссылка 3</p>
      <p className="mb-10" onClick={() => scrollToRef(ref4)}>
        ссылка 4
      </p>
      <h5 className="font-semibold">ссылка 1</h5>
      Facebook выкатил целый пакет моделей работы с языками: - Более продвинутую модель перевода
      речи, теперь с шёпотом, скоростью речи и эмоциями. Выглядит, конечно, огненно! И все веса
      открыты! - Плюс стриминг-модель для перевода практически в режиме реального времени (за 2
      секунды, как синхронные переводчики) - State-of-the-art распознавание речи и текста и перевод
      в речь и текст на 100 языках! Удивляюсь, конечно, как компания, которая делает, блин, соцсеть,
      умудрилась сделать для AI опенсорса в 10 раз больше тех, кто специально занимается только
      этим. Попробуйте демку тут - https://seamless.metademolab.com/expressive Читать подробнее и
      скачивать - здесь https://ai.meta.com/research/seamless-communication/
      <h5 className="font-semibold" ref={ref2}>
        ссылка 2
      </h5>
      OpenAI письмо прислал с планами. Мы продолжаем вносить улучшения в GPT, основываясь на ваших
      отзывах. Для улучшения работы мы обновили интерфейс конфигурации, включили тестирование в один
      клик, добавили отладочные сообщения в предварительном просмотре и теперь разрешили
      использование нескольких доменов. Также поступали вопросы о загруженных файлах. Загруженные
      файлы могут быть загружены при использовании Code Interpreter, поэтому мы сделали эту функцию
      по умолчанию отключенной и добавили сообщения, чтобы лучше объяснить это. Если у вас есть
      дополнительные замечания, мы будем рады услышать их здесь. Что касается дальнейших действий,
      то мы планируем запустить GPT Store в начале следующего года. Хотя мы рассчитывали выпустить
      его в этом месяце, несколько неожиданных вещей не дали нам это сделать. Тем временем в
      ближайшее время мы подготовим еще несколько отличных обновлений для ChatGPT. Спасибо, что
      уделяете время созданию GPT.
      <h5 className="font-semibold" ref={ref3}>
        ссылка 3
      </h5>
      Кажется история с OpenAI действительно завершилась. Выпустили пресс-релиз с окончанием истории
      и что будет дальше. Итого: 1. Сэм возвращается как CEO, Мира Мурати как CTO, Грэг как директор
      и как со-CEO. 2. Первоначальное правление Брат Тейлор (Co-CEO Salesforce) - председатель,
      Ларри Саммерс (из правительства), Адам Д’Анжело (фаундер Quora) - из прошлого совета. Будет
      один наблюдатель из Microsoft без права голоса. 3. Илья Суцкевер уйдет из совета директоров,
      но пока идут переговоры, чтобы остался в компании. 4. Не потеряли ни одного сотрудника и ни
      одного клиента. 5. Что дальше: работать над безопасностью AI, выпускать классные продукты,
      сформировать другую, более стабильную структуру управления, разносторонний совет директоров и
      провести аудит событий последних дней.
      <h5 className="font-semibold" ref={ref4}>
        ссылка 4
      </h5>
      Был вчера на предпринимательской конференции в Университете Кипра и выступал преподаватель из
      INSEAD. И практически всё выступление было про эксайтмент, эмейзинг AI и революшен. Что скоро
      всех врачей заменят роботы, машины будут продавать электричество из своих батарей другим
      машинам на стоянке и что chatgpt решит все твои проблемы, лишь бы захотеть. Уверенно и
      эмоционально рассказывал. А ты тут сотый раз уже переписываешь промпт и пытаешься заставить не
      выдумывать ответ, если не знает... Вот и думаю, а как правильно? Излучать оптимизм и приводить
      людей в секту AGI золотыми кущами или всё-таки стоять на стороне реализма и не завышать
      ожидания?
    </>
  );
};

export default TestPage;
