import React, { useContext, useEffect, useState } from 'react';
import LogoHeader from '../Components/LogoHeader';
import Header from '../Components/Header';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import {
  fetchBranch,
  selectBranchId,
  selectColorMainBackground,
  selectSubscription_options,
  selectSubscription_state,
  selectUseTips,
} from '../store/slices/branchSlice';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import Unavailible from '../Components/Unavailable';
import ExpiredSubscription from '../Components/ExpiredSubscription';
import TipsRecipientCard from '../Components/TipsRecipientCard';
import { fetchTipsRecipients, selectAllTipsRecipients } from '../store/slices/tipsSlice';

const TipsPage = () => {
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const subsOptions = useSelector(selectSubscription_options);

  const subsState = useSelector(selectSubscription_state);

  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Добавляем state для отслеживания загрузки

  const { branchLink, tableUrl } = useParams();
  const dispatch = useDispatch();
  dispatch(setBranchLinkGlobal(branchLink));

  const branchId = useSelector(selectBranchId) || null;
  const tipsRecipients = useSelector(selectAllTipsRecipients);
  // console.log('~ ++---- TipsPage branchId =', branchId);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredRecipients = tipsRecipients.filter((recipient) =>
    recipient.fullname.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    if (branchId === null && branchLink) {
      setIsLoading(true); // Устанавливаем загрузку в true
      dispatch(fetchBranch(branchLink))
        .then((action) => {
          if (action.payload) {
            setIsLoading(false); // Устанавливаем загрузку в false
          } else {
            navigate(`/notfoundpage`);
          }
        })
        .catch((error) => {
          setIsLoading(false); // Устанавливаем загрузку в false
        });
    } else {
      setIsLoading(false); // Устанавливаем загрузку в false
    }

    if (tipsRecipients.length === 0) {
      dispatch(fetchTipsRecipients(branchLink));
    }
  }, [branchId, branchLink, dispatch]);

  const pageName = 'tips';

  const branchUseTips = useSelector(selectUseTips);
  if (!branchUseTips) {
    return (
      <div className={` h-screen`}>
        <LogoHeader></LogoHeader>
        <Header pageName={pageName} />
        <Unavailible text={'Страница чаевых не доступна'}></Unavailible>
      </div>
    );
  }

  if (isLoading) {
    return <div>Загрузка...</div>; // Отображаем индикатор загрузки
  }

  // подписка истекла
  if (subsState === 0) {
    return <ExpiredSubscription />;
  }
  // тариф только отзывы. Остальное отключаем
  if (subsOptions <= 1) {
    return <Unavailible text={'Страница недоступна'} />;
  }

  return (
    <div className={` h-screen main-page tips-page`} style={{ backgroundColor: colors.colorMainBackground }}>
      <div className="tips-header">
        <LogoHeader></LogoHeader>
        <Header pageName={pageName} bgColor={colors.colorTopMainMenu} />
      </div>
      <div
        style={{
          color: colors.colorFontFeedback,
          fontFamily: colors.fontForFeedback,
          fontSize: `${colors.fontForFeedbackSize}px`,
          fontWeight: `${colors.fontForFeedbackWeight}`,
          fontStyle: `${colors.fontForFeedbackStyle}`,
        }}
        className="tips-text text-center text-tips mt-3 font-medium ">
        <span className="tips-text-p">Выберите, кому хотите отправить чаевые</span>
      </div>
      <div className="mt-4 div-tips-search mx-2">
        <input
          type="text"
          placeholder="Поиск по имени"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ backgroundColor: colors.colorSearch }}
          className="tips-search-input w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="tips-cards mt-6 ">
        {filteredRecipients.length > 0 ? (
          filteredRecipients.map((recipient) => (
            <TipsRecipientCard
              key={recipient.id}
              fullname={recipient.fullname}
              photo_url={recipient.photo_url}
              layout_id={recipient.layout_id}
              bgColor={colors.colorDishCard}
              font={colors.fontForDishCardTitle}
              fontColor={colors.colorFontDishTitle}
              borderRadius={colors.dishCardBorderRadius}
              borderSize={colors.dishCardBorderSize}
              colorBorder={colors.colorDishCardBorder}
            />
          ))
        ) : (
          <div
            style={{
              color: colors.colorFontFeedback,
              fontFamily: colors.fontForFeedback,
              fontSize: `${colors.fontForFeedbackSize}px`,
              fontWeight: `${colors.fontForFeedbackWeight}`,
              fontStyle: `${colors.fontForFeedbackStyle}`,
            }}>
            <p className="text-center">Нет доступных получателей чаевых.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TipsPage;
