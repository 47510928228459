// tableUrlSlice.js
import { createSlice } from '@reduxjs/toolkit';

// tableUrlSlice.js
import { createAsyncThunk } from '@reduxjs/toolkit';



export const checkTableUrlInDB = createAsyncThunk(
  'tableUrl/checkInDB',
  async (tableUrl, thunkAPI) => {
    try {
      // // console.log('~ $$$$$ checkTableUrlInDB вызван');
      const response = await fetch(`${process.env.REACT_APP_URL}/api/checkTableExists?tableUrl=${tableUrl}`);
      const data = await response.json();
      return { exists: data.exists, name: data.name };
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при проверке URL в БД');
    }
  }
);

// Добавьте этот Thunk в ваш слайс и обработайте его состояния (pending, fulfilled, rejected).


const tableUrlSlice = createSlice({
  name: 'tableUrl',
  initialState: {
    tableUrl: null,
    exists: false, // новая переменная
    loading: false, // для отображения статуса загрузки
    error: null, // для отображения ошибок
    name: null, 
  },
  reducers: {
    setTableUrl: (state, action) => {
      state.tableUrl = action.payload;
    },
    clearTableUrl: state => {
      state.tableUrl = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkTableUrlInDB.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkTableUrlInDB.fulfilled, (state, action) => {
        state.loading = false;
        state.exists = action.payload.exists; // обновляем состояние exists
        state.name = action.payload.name;
        // console.log('~ add Case state exists = ', action.payload.exists);
        // console.log('~ add Case state name = ', action.payload.name);
      })
      .addCase(checkTableUrlInDB.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // сохраняем ошибку
      });
  }
});



export const { setTableUrl, clearTableUrl } = tableUrlSlice.actions;

export const selectTableUrl = state => state.tableUrl.tableUrl;
export const selectTableName = state => state.tableUrl.name;

export default tableUrlSlice.reducer;
