import { createSlice } from '@reduxjs/toolkit';

export const fontsSlice = createSlice({
  name: 'fonts',
  initialState: {
    primaryFont: 'Open Sans', // Значение по умолчанию
    secondaryFont: 'Roboto', // Значение по умолчанию
  },
  reducers: {
    setPrimaryFont: (state, action) => {
      state.primaryFont = action.payload;
    },
    setSecondaryFont: (state, action) => {
      state.secondaryFont = action.payload;
    },
  },
});

export const { setPrimaryFont, setSecondaryFont } = fontsSlice.actions;

export default fontsSlice.reducer;
