import React, { useContext, useState } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

const CustomAlert = ({
  isOpen,
  onClose,
  onConfirm,
  colorMainBackground,
  allButtonsBorderRadius,
  colorFontBranchName
}) => {
  if (!isOpen) return null;

  const handleWaiter = () => {
    onConfirm('waiter');
    onClose();
  };

  const handleBill = () => {
    onConfirm('bill');
    onClose();
  };

  return (
    <div
      className={`div-custom-alert-1 fixed bg-opacity-70 bg-black top-0 left-0 w-full h-full flex justify-center items-center`}>
      <div
        style={{ backgroundColor: colorMainBackground, color: colorFontBranchName }}
        className={`div-custom-alert-2 p-4 rounded-lg   `}>
        <div className="div-custom-alert-3">
          <button
            style={{
              borderRadius: `${allButtonsBorderRadius}px`,
              borderColor: colorFontBranchName,
            }}
            onClick={handleWaiter}
            className="btn btn-call-waiter w-full mb-2 p-2  border border-gray-400 rounded shadow">
            Позвать официанта
          </button>
        </div>
        <div className="div-custom-alert-bill">
          <button
           style={{
            borderRadius: `${allButtonsBorderRadius}px`,
            borderColor: colorFontBranchName,
          }}
            onClick={handleBill}
            className="btn btn-custom-alert-bill w-full mb-6 p-2  border border-gray-400 rounded shadow">
            Попросить счет
          </button>
        </div>
        <div className="div-custom-alert-cancel">
          <button 
           style={{
            borderRadius: `${allButtonsBorderRadius}px`,
            borderColor: colorFontBranchName,
          }}
          onClick={onClose} className="btn btn-custom-alert-cancel w-full p-2  border border-gray-400 rounded shadow">
            Отмена
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
