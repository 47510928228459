import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk для загрузки сторисов по ID филиала
export const fetchStoriesByBranchLink = createAsyncThunk(
    'stories/fetchByBranchLink',
    async (branchLink, { rejectWithValue }) => {
        // console.log("🎈 ~ fetchStoriesByBranchLink:")
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/stories/byLink/${branchLink}`);
            // console.log('🎆 ~ response.data fetchStoriesByBranchLink = ', response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Создание slice
export const storiesSlice = createSlice({
    name: 'stories',
    initialState: {
        items: [],
        loading: false,
        error: null,
    },
    reducers: {
        // Опционально: редьюсеры для синхронных действий с состоянием
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStoriesByBranchLink.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStoriesByBranchLink.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
            })
            .addCase(fetchStoriesByBranchLink.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Не удалось загрузить сторисы';
            });
    },
});

// Экспорт редьюсера
export default storiesSlice.reducer;
