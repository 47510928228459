import React from 'react';
import Stories from 'react-insta-stories';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { selectColorMainBackground } from '../store/slices/branchSlice';

const StoriesViewer = ({ stories, onClose, vvv }) => {
  //const formattedStories = stories.flatMap(group => group.map(story => {
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';
  // console.log('~ 🥣 colors.fontForTitleStorySize: ', colors.fontForTitleStorySize);
  const opacityDiv = 1 - Number(colors.opacityDivTitleAndTextStory) / 100;
  // console.log('~ 🥓 opacityDiv: ', opacityDiv);
  const formattedStories = stories.map((story) => {
    // Для обычных изображений без фонового режима
    return {
      content: ({ close }) => (
        <div
        className='div-storyviewer-1'
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative', // Указываем, что позиционирование дочерних элементов будет относительно этого контейнера
          }}>
          <img
          className='img-storyviewer-2'
            src={`${process.env.REACT_APP_URL}${story.url_image_story}`}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
          <div
          className='div-storyviewer-3'
            style={{
              backgroundColor: colors.colorDivTitleAndTextStory,
              opacity: opacityDiv,
              position: 'absolute', // Заголовок и подзаголовок абсолютно позиционированы
              bottom: 0, // Размещаем текст в нижней части изображения
              left: 0,
              width: '100%', // Ширина контейнера текста соответствует ширине изображения
              padding: '10px', // Отступы внутри контейнера текста
              color: 'red', // Цвет текста
              textAlign: 'center', // Выравнивание текста по центру
            }}>
            <h1
            className='h-storyviewer-title-4'
              style={{
                fontFamily: colors.fontForTitleStory,
                color: colors.colorFontTitleStory,
                fontSize: `${colors.fontForTitleStorySize}px`,
                fontWeight: colors.fontForTitleStoryWeight,
                fontStyle: colors.fontForTitleStoryStyle,
              }}>
              {story.title}
            </h1>
            <p
            className='p-storyviewer-text-5'

              style={{
                fontFamily: colors.fontForTextStory,
                color: colors.colorFontTextStory,
                fontSize: `${colors.fontForTextSize}px`,
                fontWeight: colors.fontForTextWeight,
                fontStyle: colors.fontForTextStyle,
                
              }}>
              {story.text}
            </p>
          </div>
        </div>
      ),
      duration: story.duration,
    };
  });
  //)
  return (
    <div className="storiesViewerContainer">
      <div
        className="p-storyviewer-6 bg-slate-300 rounded-full w-6 h-6 flex justify-center items-center"
        onClick={onClose}
        style={{ position: 'absolute', top: 20, right: 20, zIndex: 1010 }}>
        <MdClose className='p-storyviewer-close-7' />
      </div>
      <Stories stories={formattedStories} defaultInterval={1500} width="100%" height="100%" />
    </div>
  );
};

export default StoriesViewer;
