import React, { useEffect, useState } from 'react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // не забудьте добавить стили
import LazyLoad from 'react-lazyload';

const Card = React.memo(
  ({
    cardViewVariant,
    title,
    description,
    price,
    id,
    tags,
    img_url,
    tableExists,
    isDelivery,
    output,
    onAddToCart,
    onRemoveFromCart,
    currentQuantity,

    setShowFooter,
    branchLink,
    long_description,
    handleOpenModal,
    bgColor,
    colorOrderButtonPlus,
    colorFontTitle,
    colorFontDishDescription,
    colorFontDishPrice,
    colorFontDishOutput,
    colorDishCardBorder,
    colorFontDishOrder,
    colorFontDishOrderCount,
    fontForDishCardTitle,
    fontForDishCardDescription,
    fontForDishCardOutput,
    fontForDishCardPrice,
    fontForDishCardTitleSize,
    fontForDishCardTitleWeight,
    fontForDishCardTitleStyle,
    fontForDishCardDescriptionSize,
    fontForDishCardDescriptionWeight,
    fontForDishCardDescriptionStyle,
    fontForDishCardOutputSize,
    fontForDishCardOutputWeight,
    fontForDishCardOutputStyle,
    fontForDishCardPriceSize,
    fontForDishCardPriceWeight,
    fontForDishCardPriceStyle,
    colorDishCardButtonBorder,
    dishCardButtonBorderRadius,
    dishCardButtonBorderSize,
    dishCardBorderRadius,
    dishCardBorderSize,
  }) => {
    // тема
    const dish = { id, title, description, price, img_url };

    /*    useEffect(() => {
      console.log(`Компонент был перерисован ${title}`);
    });  */

    const useOrderLocal = true; //useSelector(selectUseOrderLocal);
    const handleAddToCart = () => {
      const dish = { id, title, description, price, img_url };
      onAddToCart(dish);
    };

    const handleRemoveFromCart = () => {
      const dish = { id, title, description, price, img_url };
      onRemoveFromCart(dish);
    };

    const adjustedRadius = Math.max(0, dishCardBorderRadius - dishCardBorderSize);

    return (
      <>
        {cardViewVariant === 0 ? (
          <div className=" dish-card dish-card-list pl-1 pb-1" style={{ backgroundColor: bgColor }}>
            <div className="flex flex-row items-center justify-between">
              {/* Добавлен justify-between для выравнивания содержимого */}
              <div
                className="h5-title h5-title-list flex-grow"
                style={{
                  color: colorFontTitle,
                  fontFamily: fontForDishCardTitle,
                  fontSize: `${fontForDishCardTitleSize}px`,
                  fontWeight: `${fontForDishCardTitleWeight}`,
                  fontStyle: `${fontForDishCardTitleStyle}`,
                }}>
                {title}
              </div>{' '}
              {/* flex-grow позволяет title занимать все доступное пространство */}
              {/* Обертка для output и price для группировки их вместе */}
              <div
                className="h5-output h5-output-list text-xs font-normal mr-3 whitespace-nowrap"
                style={{
                  color: colorFontDishOutput,
                  fontFamily: fontForDishCardOutput,
                  fontSize: `${fontForDishCardOutputSize}px`,
                  fontWeight: `${fontForDishCardOutputWeight}`,
                  fontStyle: `${fontForDishCardOutputStyle}`,
                }}>
                {output}
              </div>
              {/* Добавлен класс whitespace-nowrap */}
              <div
                className="p-price p-price-list"
                style={{
                  color: colorFontDishPrice,
                  fontFamily: fontForDishCardPrice,
                  fontSize: `${fontForDishCardPriceSize}px`,
                  fontWeight: `${fontForDishCardPriceWeight}`,
                  fontStyle: `${fontForDishCardPriceStyle}`,
                }}>{`${parseFloat(price)} ₽`}</div>
              <div className="ml-2 mt-2 mr-1">
                {(isDelivery || (useOrderLocal && tableExists)) && (
                  <div className="btn-dish-card dish-card-order-minus-btn btn-dish-card-list dish-card-order-minus-btn-list flex flex-row mr-1 mb-1 items-center ">
                    {currentQuantity > 0 && (
                      <button
                        style={{
                          backgroundColor: colorOrderButtonPlus,
                          color: colorFontDishOrder,
                          paddingBottom: '4px',
                          borderColor: colorDishCardButtonBorder,
                          borderRadius: `${dishCardButtonBorderRadius}px`,
                          borderWidth: `${dishCardButtonBorderSize}px`,
                        }}
                        onClick={handleRemoveFromCart}
                        className=" btn-card-order-minus btn-card-order-minus-list justify-center items-center border w-7 h-7  rounded-md shadow-sm font-bold "
                        /*  onClick={handleRemoveFromCart} */
                      >
                        <span className="btn-card-order-minus btn-card-order-minus-list">-</span>
                      </button>
                    )}
                    <div
                      className="div-quantity div-quantity-list font-bold"
                      style={{
                        color: colorFontDishOrderCount,
                        fontFamily: fontForDishCardDescription,
                      }}>
                      {currentQuantity > 0 && <p className="px-2">{currentQuantity}</p>}
                    </div>

                    <button
                      className="btn-dish-card btn-card-order-plus btn-dish-card-list  dish-card-order-plus-btn dish-card-order-plus-btn-list justify-center items-center border w-7 h-7  rounded-md shadow-sm font-bold"
                      style={{
                        backgroundColor: colorOrderButtonPlus,
                        color: colorFontDishOrder,
                        paddingBottom: '4px',
                        borderColor: colorDishCardButtonBorder,
                        borderRadius: `${dishCardButtonBorderRadius}px`,
                        borderWidth: `${dishCardButtonBorderSize}px`,
                      }}
                      onClick={handleAddToCart}>
                      <span className="span-card-order-plus">+</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div
              className="h5-description h5-description-list text-xs font-light w-3/5"
              style={{
                color: colorFontDishDescription,
                fontFamily: fontForDishCardDescription,
                fontSize: `${fontForDishCardDescriptionSize}px`,
                fontWeight: `${fontForDishCardDescriptionWeight}`,
                fontStyle: `${fontForDishCardDescriptionStyle}`,
              }}>
              {description}
            </div>
          </div>
        ) : (
          /* первая общая обертка  */
          <div
            key={id}
            className={`wrapper-${id} div-card-1 dish-card-wrapper  mt-2 `}
            style={{
              backgroundColor: bgColor,
              borderRadius: `${dishCardBorderRadius}px`,
            }}>
            {/* вторая общая обертка */}
            <div
              id={id}
              style={{
                borderColor: colorDishCardBorder,
                borderRadius: `${dishCardBorderRadius}px`,
                borderWidth: `${dishCardBorderSize}px`,
              }}
              className={`card-${id} div-card-2 dish-card dish-card-grid flex flex-col h-full justify-between shadow-sm pt-2 pl-2 pr-2`}>
              {/* верхняя часть карточки   */}
              <div className="div-card-top">
                {/* картинка  */}
                <div className="div-card-img mb-2">
                  {img_url ? (
                   /*  <LazyLoad height={150} offset={100}> */
                      <img
                        className="img-card object-contain rounded-t-lg "
                        src={`${process.env.REACT_APP_URL}/${img_url}`}
                        alt={title}
                        style={{
                          borderTopLeftRadius: `${adjustedRadius}px`,
                          borderTopRightRadius: `${adjustedRadius}px`,
                        }}
                      />
                  /*   </LazyLoad> */
                  ) : (
                    <div className="udxkmozroc md:rounded-none md:rounded-l-lg"></div>
                  )}
                </div>
                {/* заголовок */}
                <div className="div-title-1">
                  <h5
                    style={{
                      color: colorFontTitle,
                      fontFamily: fontForDishCardTitle,
                      fontSize: `${fontForDishCardTitleSize}px`,
                      fontWeight: `${fontForDishCardTitleWeight}`,
                      fontStyle: `${fontForDishCardTitleStyle}`,
                    }}
                    className="h5-title h5-title-grid self-start font-bold text-gray-900  text-sm whitespace-normal truncate">
                    <span>{title}</span>
                  </h5>
                </div>

                {/* выход блюда */}
                <div className="div-output-1">
                  <h5
                    style={{
                      color: colorFontDishDescription,
                      fontFamily: fontForDishCardDescription,
                      fontSize: `${fontForDishCardDescriptionSize}px`,
                      fontWeight: `${fontForDishCardDescriptionWeight}`,
                      fontStyle: `${fontForDishCardDescriptionStyle}`,
                    }}
                    className="h5-description h5-description-grid pb-1 pt-2 leading-3 text-xs font-normal self-start  text-gray-800  whitespace-normal truncate">
                    <span> {description}</span>
                  </h5>
                </div>

                {/* выход блюда */}
                <div className="div-output-1">
                  <h5
                    style={{
                      color: colorFontDishOutput,
                      fontFamily: fontForDishCardOutput,
                      fontSize: `${fontForDishCardOutputSize}px`,
                      fontWeight: `${fontForDishCardOutputWeight}`,
                      fontStyle: `${fontForDishCardOutputStyle}`,
                    }}
                    className="h5-output h5-output-grid text-xs font-normal  self-start text-gray-900 whitespace-normal ">
                    <span> {output}</span>
                  </h5>
                </div>
                {/* тэги */}
                <div className="flex flex-row flex-wrap pt-2 ">
                  {tags.map((tag) => {
                    // Проверяем, существует ли icon_url и не равен ли он null
                    if (tag.icon_url) {
                      return (
                        <div key={tag.id}>
                          <Tippy content={tag.description} trigger="click">
                            <img
                              className="dish-tag h-6 w-6 mr-2"
                              src={`${process.env.REACT_APP_URL}/${tag.icon_url}`}
                              alt=""
                            />
                          </Tippy>
                        </div>
                      );
                    }
                    return null; // Возвращаем null, если icon_url не существует или равен null
                  })}
                </div>
              </div>

              {/* нижняя часть карточки  */}
              <div className="div-card-bottom flex flex-col justify-between ">
                <div className="flex flex-row justify-between">
                  {/* цена  */}
                  <div className=" ">
                    <p
                      style={{
                        color: colorFontDishPrice,
                        fontFamily: fontForDishCardPrice,
                        fontSize: `${fontForDishCardPriceSize}px`,
                        fontWeight: `${fontForDishCardPriceWeight}`,
                        fontStyle: `${fontForDishCardPriceStyle}`,
                      }}
                      className="p-price p-price-grid text-sm text-gray-700  py-2 font-bold">{`${parseFloat(
                      price,
                    )} ₽`}</p>
                  </div>
                  {/* кнопки - + */}
                  <div>
                    {(isDelivery || (useOrderLocal && tableExists)) && (
                      <div className="btn-dish-card btn-dish-card-grid dish-card-order-minus-btn dish-card-order-minus-btn-grid flex flex-row mr-1 mb-1 items-center ">
                        {currentQuantity > 0 && (
                          <button
                            onClick={handleRemoveFromCart}
                            style={{
                              backgroundColor: colorOrderButtonPlus,
                              color: colorFontDishOrder,
                              paddingBottom: '4px',
                              borderColor: colorDishCardButtonBorder,
                              borderRadius: `${dishCardButtonBorderRadius}px`,
                              borderWidth: `${dishCardButtonBorderSize}px`,
                            }}
                            className="btn-card-order-minus justify-center items-center border w-7 h-7  rounded-md shadow-sm font-bold "
                            /* onClick={handleRemoveFromCart} */
                          >
                            <span className="btn-card-order-minus">-</span>
                          </button>
                        )}
                        <div
                          className="div-quantity div-quantity-grid font-bold"
                          style={{
                            color: colorFontDishOrderCount,
                            fontFamily: fontForDishCardDescription,
                          }}>
                          {currentQuantity > 0 && <p className="px-2">{currentQuantity}</p>}
                        </div>

                        <button
                          className="btn-dish-card btn-dish-card-grid btn-card-order-plus btn-card-order-plus-grid dish-card-order-plus-btn dish-card-order-plus-btn-grid justify-center items-center border w-7 h-7  rounded-md shadow-sm font-bold"
                          style={{
                            backgroundColor: colorOrderButtonPlus,
                            color: colorFontDishOrder,
                            paddingBottom: '4px',
                            borderColor: colorDishCardButtonBorder,
                            borderRadius: `${dishCardButtonBorderRadius}px`,
                            borderWidth: `${dishCardButtonBorderSize}px`,
                          }}
                          onClick={handleAddToCart}>
                          <span className="span-card-order-plus">+</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  },
);

export default React.memo(Card);
