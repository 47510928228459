import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchTipsRecipients = createAsyncThunk(
    'tips/fetchTipsRecipients',
    async (branchLink) => {
        // console.log('~ 🐆 fetchTipsRecipients');
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/tips/recipients-app`, {
            params: {
                branchLink: branchLink,
            },
        });
        return response.data;
    }
);



const tipsSlice = createSlice({
    name: 'tips',
    initialState: {
        tipsRecipients: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTipsRecipients.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTipsRecipients.fulfilled, (state, action) => {
                state.loading = false;
                // console.log('~ 🎆 action.payload fetchTipsRecipients: ', action.payload);
                state.tipsRecipients = action.payload;
            })
            .addCase(fetchTipsRecipients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            ;
    },
});

export const selectAllTipsRecipients = (state) => state.tips.tipsRecipients;


export default tipsSlice.reducer;