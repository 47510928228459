import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBranch,
  selectBranchId,
  selectColorMainBackground,
  selectSubscription_state,
  selectUseFeedback,
} from '../store/slices/branchSlice';
import { fetchPlatformsByBranchId } from '../store/slices/reviewPlatformSlice';
import { fetchBranchOptionsByBranchId } from '../store/slices/branchOptionsSlice';
import { checkTableExists } from '../store/slices/tablesSlice';
import { checkTableUrlInDB, setTableUrl } from '../store/slices/tableUrlSlice';
import { useParams } from 'react-router-dom';
import LogoHeader from '../Components/LogoHeader';
import RatingStars from '../Components/RatingStars';
import Header from '../Components/Header';
import { setBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import Unavailible from '../Components/Unavailable';
import ExpiredSubscription from '../Components/ExpiredSubscription';

const DirectFeedbackPage = () => {

  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branchLink } = useParams();
  const branchId = useSelector(selectBranchId) || null;
  const queryParams = new URLSearchParams(window.location.search);
  const tableUrl = queryParams.get('t');
  const [isLoading, setIsLoading] = useState(true); // индикатор загрузки страницы

  // console.log(' ~ -------- tableUrl from params DFP = ', tableUrl);

  const subsState = useSelector(selectSubscription_state);
  // console.log('~ Статус подписки = ', subsState);

  //const tableUrl = tableId;
  // //console.log('~ tableId =', tableId);
  useEffect(() => {
    setIsLoading(true); // устанавливаем в true перед началом загрузки
    if (branchId === null && branchLink) {
      dispatch(setBranchLinkGlobal(branchLink));

      dispatch(fetchBranch(branchLink))
        .then((action) => {
          if (action.payload) {
            const branchId = action.payload.id; // или каким-либо другим образом получите branchId из action
            setIsLoading(false); // устанавливаем в false после успешной загрузки
            return Promise.all([
              dispatch(fetchPlatformsByBranchId(branchId)),
              dispatch(fetchBranchOptionsByBranchId(branchId)),
              dispatch(checkTableUrlInDB(tableUrl)),
              dispatch(setTableUrl(tableUrl)),
            ]);
          }
          setIsLoading(false);
          if (branchId == null) {
            navigate(`/notfoundpage`);
          }
        })
        .catch((error) => {
          // Обработка ошибок
          setIsLoading(false); // устанавливаем в false, если произошла ошибка
        });
    } else {
      setIsLoading(false);
      dispatch(fetchPlatformsByBranchId(branchId));
      dispatch(fetchBranchOptionsByBranchId(branchId));
      dispatch(checkTableUrlInDB(tableUrl));
      dispatch(setTableUrl(tableUrl));
    }
  }, [dispatch, branchLink, tableUrl]);

  const pageName = 'feedback';

  if (subsState === 0) {
    return <ExpiredSubscription />;
  }


  if (isLoading) {
    return <div>Загрузка...</div>; // или другой компонент-загрузчик
  }



  return (
    <div className={` div-dfp-1 main-page h-screen`} style={{ backgroundColor: colors.colorMainBackground }}>
      <LogoHeader></LogoHeader>
      <Header pageName={pageName} bgColor={colors.colorTopMainMenu}/>
      <RatingStars></RatingStars>
     
    </div>
  );
};

export default DirectFeedbackPage;
