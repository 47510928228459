import { createSlice } from '@reduxjs/toolkit';

// Начальное состояние
const initialState = {
  branchLinkGlobal: null,
};

// Создание слайса
const branchLinkGlobalSlice = createSlice({
  name: 'branchLinkGlobal',
  initialState,
  reducers: {
    setBranchLinkGlobal: (state, action) => {
      state.branchLinkGlobal = action.payload;
    },
    clearBranchLinkGlobal: (state) => {
      state.branchLinkGlobal = null;
    },
  },
});

// Экспорт селекторов
export const selectBranchLinkGlobal = (state) => state.branchLinkGlobal.branchLinkGlobal;

// Экспорт экшенов
export const { setBranchLinkGlobal, clearBranchLinkGlobal } = branchLinkGlobalSlice.actions;

// Экспорт редьюсера
export default branchLinkGlobalSlice.reducer;
