import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCartDelivery,
  selectCartDelivery,
  selectTotalPriceDelivery,
  selectTotalQuantityDelivery,
} from '../store/slices/cartDeliverySlice';
import {
  selectChatiIdDelivery,
  selectChatiIdOrder,
  selectColorMainBackground,
} from '../store/slices/branchSlice';
import axios from 'axios';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { selectBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import { ThemeContext } from '../contexts/ThemeContext'; // тема
import LogoHeader from '../Components/LogoHeader';
import Header from '../Components/Header';

const OrderDeliveryPage = () => {
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const branchLinkGlobal = useSelector(selectBranchLinkGlobal);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [deliveryComment, setDeliveryComment] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneAlert, setPhoneAlert] = useState(false);

  const cartDelivery = useSelector(selectCartDelivery);
  const order = cartDelivery.map((item) => `${item.title}: ${item.quantity}`).join('\n');
  const totalPrice = useSelector(selectTotalPriceDelivery).toFixed(2);
  // // console.log('~ order =', order);

  const chatIdDelivery = useSelector(selectChatiIdDelivery);

  const handleSubmit = () => {
    if (!phoneNumber) {
      setPhoneAlert(true);
      return;
    } else {
      setPhoneAlert(false);
    }

    const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

    // Собираем данные для отправки в Телеграм
    const sendText = `
    🚚 Заказ на доставку
    
Имя: ${userName}

📞Номер телефона: ${phoneNumber}

📌Адрес доставки: ${deliveryAddress}

Комментарий: ${deliveryComment}
    
📃Заказ: 

${order}

Итого: ${totalPrice} ₽ 
    `;

    // Отправляем сообщение в Телеграм
    // console.log('~ Отправляем следующее сообщение в Телеграм:', sendText);

    return axios
      .post(endpoint, {
        chat_id: chatIdDelivery,
        text: sendText,
      })
      .then((response) => {
        // console.log('~ Message sent to Telegram:', response.data);
        dispatch(clearCartDelivery()); // Используем dispatch здесь
        navigate(`/${branchLinkGlobal}/deliveryend/${tableUrl ? `?t=${tableUrl}` : ''}`); // Переадресуем пользователя
      })
      .catch((error) => {
        console.error('Error sending message to Telegram:', error);
      });
  };

  const handleCancel = () => {
    navigate(`/${branchLinkGlobal}/delivery/${tableUrl ? `?t=${tableUrl}` : ''}`);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableUrl = queryParams.get('t');


  return (
    <div className='div-odp-1 main-page' style={{ backgroundColor: colors.colorMainBackground, color: colors.colorFontBranchName }}>
      <LogoHeader />
      <Header />
      <div className="div-odp-2 pl-4 pr-4 pt-6  flex flex-col items-center">
        Заказ на доставку:
        <br />
        <div className='div-odp-3'>
          <table
            className="table-odp-4 w-80 md:w-[300px]    py-2 px-2  border   pt-2"
            style={{ borderColor: colors.colorSearchBorder }}>
            <tbody className='tbody-odp-5'>
              {cartDelivery.map((item, index) => (
                <tr key={index} className='tr-odp-6'>
                  <td style={{ color: colors.colorFontBranchName }} className="td-odp-7 text-left py-2 px-3">
                    {item.title}
                  </td>
                  <td style={{ color: colors.colorFontBranchName }} className="td-odp-8 text-left py-2 px-3">
                    {item.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <span className='span-odp-summ-9'> Итого: {totalPrice} ₽</span>
        <div className='div-odp-10'>
          <div className="div-odp-11 container mx-auto p-4">
            <div className="div-odp-12 mb-4">
              <label
                className="label-odp-13 block text-sm font-medium text-slate-800 "
                style={{ color: colors.colorFontBranchName }}>
                Имя
              </label>
              <input
                type=" name"
                className="input-order-delivery input-odp-14 mt-1 p-2 w-full border    py-2 px-2 text-gray-700 bg-slate-100  border-1 border-slate-500 rounded-sm shadow focus:outline-none focus:shadow-outline"
                placeholder="Как к вам обращаться"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                style={{
                  backgroundColor: colors.colorSearch,
                  borderColor: colors.colorSearchBorder,
                  borderRadius: `${colors.allInputsBorderRadius}px`,
                  color: colors.colorFontSearchInput,

                }}
              />
              <label
                style={{ color: colors.colorFontBranchName }}
                className="label-odp-14 pt-4 block text-sm font-medium text-slate-800 ">
                Телефон
              </label>
              {phoneAlert && <div className="text-red-500">Введите телефон</div>}
              <input
                type="tel"
                className="input-odp-15 input-order-delivery mt-1 p-2 w-full    py-2 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-sm shadow focus:outline-none focus:shadow-outline"
                placeholder="Введите Ваш телефон"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{
                  backgroundColor: colors.colorSearch,
                  borderColor: colors.colorSearchBorder,
                  borderRadius: `${colors.allInputsBorderRadius}px`,
                  color: colors.colorFontSearchInput
                }}
              />
            </div>

            <div className="div-odp-17 mb-4">
              <label
                style={{ color: colors.colorFontBranchName }}
                className="label-odp-18 block text-sm font-medium text-slate-800 ">
                Адрес доставки
              </label>
              <input
                type="text"
                className="input-odp-19 input-order-delivery mt-1 p-2 w-full   py-2 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-sm shadow focus:outline-none focus:shadow-outline"
                placeholder="Введите адрес доставки"
                value={deliveryAddress}
                onChange={(e) => setDeliveryAddress(e.target.value)}
                style={{
                  backgroundColor: colors.colorSearch,
                  borderColor: colors.colorSearchBorder,
                  borderRadius: `${colors.allInputsBorderRadius}px`,
                  color: colors.colorFontSearchInput

                }}
              />
            </div>

            <div className="div-odp-20 mb-4">
              <label
                style={{ color: colors.colorFontBranchName }}
                className="label-odp-21 block text-sm font-medium text-slate-800 ">
                Комментарий
              </label>
              <textarea
                style={{
                  backgroundColor: colors.colorSearch,
                  borderColor: colors.colorSearchBorder,
                  borderRadius: `${colors.allInputsBorderRadius}px`,
                  color: colors.colorFontSearchInput

                }}
                rows="4"
                className="textarea-odp-22 input-order-delivery mt-1 p-2 w-full   py-2 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-sm shadow focus:outline-none focus:shadow-outline"
                placeholder="Комментарий к доставке (по желанию)"
                value={deliveryComment}
                onChange={(e) => setDeliveryComment(e.target.value)}></textarea>
              <div className="div-odp-23 flex justify-end mt-2 ">
                <button
                  onClick={handleCancel}
                  className="btn btn-odp-cancel-24 py-2 px-4 "
                  style={{
                    backgroundColor: colors.colorOrderDivButton,
                    color: colors.colorOrderDivFontColorButton,
                    fontFamily: colors.secondaryFont,
                    fontSize: `${colors.fontForOrderDivSize}px`,
                    fontWeight: `${colors.fontForOrderDivWeight}`,
                    borderRadius: `${colors.allButtonsBorderRadius}px`, //allButtonsBorderRadius
                  }}>
                  Отменить
                </button>

                <button
                  style={{
                    backgroundColor: colors.colorOrderDivButton,
                    color: colors.colorOrderDivFontColorButton,
                    fontFamily: colors.secondaryFont,
                    fontSize: `${colors.fontForOrderDivSize}px`,
                    fontWeight: `${colors.fontForOrderDivWeight}`,
                    borderRadius: `${colors.allButtonsBorderRadius}px`, //allButtonsBorderRadius
                  }}
                  onClick={handleSubmit}
                  className="btn btn-odp-order-25 ml-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4
                  rounded"> Заказать
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDeliveryPage;
