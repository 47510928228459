import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

const ExpiredSubscription = () => {

  return (
    <div className={` w-full  flex items-center justify-center  `}>
      <div>
        <div className=" pt-10 font-semibold text-xl">Подписка истекла. Сервис недоступен.</div>
        <div className=' pt-6'>
          Сервис <a href="http://restolink.ru/" className=' text-blue-500'>RestoLink.ru</a>{' '}
        </div>
      </div>
    </div>
  );
};

export default ExpiredSubscription;
