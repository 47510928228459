import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { selectColorMainBackground } from '../store/slices/branchSlice';

const ModalCard = ({ onClose, data }) => {
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  // console.log('~ data = ', data);
  return (
    <>
      <div
        colorDishCard
        onClick={onClose}
        className="div-modal-card-1 fixed inset-0 bg-gray-800 bg-opacity-10 z-40 "></div>
      <div
        style={{ backgroundColor: colors.colorDishCard }}
        className="div-modal-card-2 mt-5 mx-7 fixed inset-0 bg-white  z-50 max-w-lg	overflow-y-auto  mb-10">
        <div
          style={{ backgroundColor: colors.colorDishCard }}
          className="div-modal-card-2 bg-white p-4 shadow-md relative w-full max-w-lg  ">
          <button
            onClick={onClose}
            className="btn-modal-card absolute top-0 right-0 mt-2 mr-2 text-slate-600 text-2xl">
            <IoMdClose style={{ color: colors.colorFontDishTitle }} /> {/* Крестик для закрытия */}
          </button>
        </div>
        {data.img_url && (
          <div className="div-modal-card-img flex items-center justify-center" >
            <img
              className="img-modal-card mt-2 object-contain rounded-t-lg w-full sm:w-1/2 md:w-1/2 "
              src={`${process.env.REACT_APP_URL}/${data.img_url}`}
              alt={data.title}
            />
          </div>
        )}
        <div className="div-modal-card-3 px-4 pt-2">
          <div style={{ color: colors.colorFontDishTitle, fontFamily: colors.fontForDishCardTitle }} className="div-modal-card-4 pt-2 font-semibold text-lg">
           <span className='span-modal-card'> {data.title}</span>
          </div>
          <div className="div-modal-card-long_description pt-2 font-normal text-sm">{data.long_description}</div>
          <div className='div-modal-card-5' style={{ color: colors.colorFontDishDescription, fontFamily: colors.fontForDishCardDescription }}>
            {data.ingredients && (
              <div className="div-modal-card-ingredients flex flex-row pt-4">
                <p className="p-modal-card-ingredients font-light text-base">
                  <span className="span-modal-card-ingredients font-normal"> Ингредиенты:</span> {data.ingredients}
                </p>
              </div>
            )}
            {/* Условный рендеринг времени приготовления */}
            {data.cooking_time && (
              <div className="div-modal-card-cooking-time flex flex-row pt-4">
                <p className="p-modal-card-cooking-time font-light text-base">
                  <span className="span-modal-card-cooking-time font-normal">Время приготовления:</span> {data.cooking_time}
                </p>
              </div>
            )}
            {data.calories && (
              <div className="div-modal-card-calories flex flex-row pt-4">
                <p className="p-modal-card-calories font-light text-base">
                  <span className="span-modal-card-calories font-normal">Калорийность:</span> {data.calories}
                </p>
              </div>
            )}
            {data.output && (
              <div className="div-modal-card-output flex flex-row pt-4">
                <p className="p-modal-card-output font-light text-base">
                  <span className="span-modal-card-output font-normal">Выход блюда:</span> {data.output}
                </p>
              </div>
            )}
            {data.price && (
              <div className="div-modal-card-price flex flex-row pt-4">
                <p className="p-modal-card-price font-light text-base">
                  <span className="span-modal-card-price font-normal">Цена:</span> {data.price} руб.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCard;
