import React, { useCallback, useRef } from 'react';
import ThemeToggle from '../Components/ThemeToggle';
import Header from '../Components/Header';
import SearchInput from '../Components/SearchInput';
import Card from '../Components/Card';

import LogoHeader from '../Components/LogoHeader';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { ThemeContext } from '../contexts/ThemeContext'; // тема

import { useLocation, useParams } from 'react-router-dom';
import CartFooter from '../Components/CartFooter';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../store/slices/cartSlice';
import { selectAllDishes } from '../store/slices/dishesSlice';
import { fetchMenuByBranchLink } from '../store/slices/dishesSlice';
import {
  fetchBranch,
  selectCardViewVariant,
  selectColorMainBackground,
  selectDeliveryPhone,
  selectDeliveryTerms,
  selectSubscription_options,
  selectUseDelivery,
} from '../store/slices/branchSlice';
import { selectDishStatus } from '../store/slices/dishesSlice';
import { checkTableUrlInDB, setTableUrl } from '../store/slices/tableUrlSlice';
import cartDeliverySlice, {
  addToCartDelivery,
  clearCartDelivery,
  loadCartFromLocalStorage,
  removeFromCartDelivery,
  selectCartDelivery,
} from '../store/slices/cartDeliverySlice';
import { setBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import CartFooterDelivery from '../Components/CartFooterDelivery';
import { useNavigate } from 'react-router-dom';
import { CiCircleChevUp } from 'react-icons/ci';
import Unavailible from '../Components/Unavailable';
import HorizontalMenu from '../Components/HorizontalMenu';
import StoryPreview from '../Components/StoryPreview';
import StoriesViewer from '../Components/StoriesViewer';
import { fetchStoriesByBranchLink } from '../store/slices/storiesSlice';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import store from 'store2';

const DeliveryPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subsOptions = useSelector(selectSubscription_options);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  //const [cardViewVariant, setCardViewVariant] = useState(0);
  //const cardViewVariant = useSelector(selectCardViewVariant);
  const cardViewVariant = useSelector((state) => state.viewVariant.cardViewVariant);

  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';
  const pageName = 'delivery';
  const isDelivery = true;

  const { branchLink } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableUrl = queryParams.get('t');
  const branchUseDelivery = useSelector(selectUseDelivery);
  const [selectedStories, setSelectedStories] = useState([]);
  const menuRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  dispatch(setBranchLinkGlobal(branchLink));

  useEffect(() => {
    if (branchLink) {
      //dispatch(loadCartFromLocalStorage());
      const st = loadCartFromLocalStorage();
      // console.log('~ 🍕st = ', st);
    }
  }, []);

  useEffect(() => {
    if (tableUrl) {
      dispatch(setTableUrl(tableUrl));
    }
    if (tableUrl) {
      dispatch(checkTableUrlInDB(tableUrl));
    }
  }, [tableUrl, dispatch]);

  const [currentBranch, setCurrentBranch] = useState(branchLink);
  const [resData, setResData] = useState([]);
  //const cartDelivery = useSelector((state) => state.cartDelivery);
  const dishes = useSelector(selectAllDishes);
  const [showFooter, setShowFooter] = useState(true);
  const dishStatus = useSelector(selectDishStatus);
  const [searchTerm, setSearchTerm] = useState('');

  const deliveryTerms = useSelector(selectDeliveryTerms);
  const deliveryPhone = useSelector(selectDeliveryPhone);
  const cartKey = `cart_delivery_${branchLink}`;

  const [storiesGroups, setStoriesGroups] = useState([]);
  const { loading, items: storiesItems, error } = useSelector((state) => state.stories);

  //const cart = useSelector((state) => (isDelivery ? state.cartDelivery.items : state.cart.items));
  const cart = useSelector(selectCartDelivery) || [];

  const hasLoadedCart = useRef(false);


  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    // Здесь можно добавить логику фильтрации блюд по выбранной категории
  };

  // 1. Сгруппировать элементы по категориям
  const groupedDishes = dishes.reduce((acc, dish) => {
    if (!acc[dish.category_name]) {
      acc[dish.category_name] = {
        dishes: [],
        position: dish.category_position,
      };
    }
    acc[dish.category_name].dishes.push(dish);
    return acc;
  }, {});

  // 2. Отсортировать группы на основе category_position
  const sortedCategories = Object.keys(groupedDishes).sort(
    (a, b) => groupedDishes[a].position - groupedDishes[b].position,
  );

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const categoriesData = sortedCategories
      .map((category) => {
        const element = document.getElementById(`category-${category}`);
        if (element) {
          const top = element.offsetTop;
          const bottom = top + element.offsetHeight;
          return { category, top, bottom };
        }
        return null;
      })
      .filter(Boolean);

    const activeCategory = categoriesData.find(
      (item) => scrollPosition >= item.top && scrollPosition < item.bottom,
    )?.category;

    setActiveCategory(activeCategory || null);

    if (activeCategory && menuRef.current) {
      const activeCategoryElement = menuRef.current.querySelector(
        `.horizontal-category[data-category="${activeCategory}"]`,
      );
      if (activeCategoryElement) {
        activeCategoryElement.scrollIntoView({
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  };

  // Load cart from localStorage on mount
  useEffect(() => {
    if (!hasLoadedCart.current) {
      /* console.log('1️⃣useEffect > loadCartFromLocalStorage'); */
      const savedCart = store.get(cartKey, []);
      /* console.log('Loaded cart from localStorage: ', savedCart); */
      if (savedCart.length > 0) {
        savedCart.forEach((item) =>
          dispatch(addToCartDelivery({ ...item, quantity: item.quantity })),
        );
      }
      hasLoadedCart.current = true; // Устанавливаем флаг после первой загрузки
    }
  }, [dispatch]);

  // Save cart to localStorage on change
  useEffect(() => {
    /* console.log('Saving cart to localStorage: ', cart); */
    store.set(cartKey, cart);
    /* console.log('2️⃣useEffect > saveToLocalStorage'); */
  }, [cart]);

   const handleAddToCart = useCallback(
    (dish) => {
     /*  console.log('3️⃣DeliveryPage handleAddToCart'); */
      setShowFooter(true);
      dispatch(addToCartDelivery({ ...dish, quantity: 1 }));
    },
    [dispatch],
  );

  const handleRemoveFromCart = useCallback((dish) => {
    dispatch(removeFromCartDelivery({ ...dish }));
  }, [dispatch]);
  
  const handleClearCart = () => {
    localStorage.removeItem(cartKey);
    dispatch(clearCartDelivery());
    setTimeout(() => {
      setShowFooter(false);
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sortedCategories]);

  useEffect(() => {
    if (dishes.length === 0) {
      dispatch(fetchMenuByBranchLink(branchLink));
    }
    dispatch(fetchBranch(branchLink));

    if (storiesItems.length === 0) {
      dispatch(fetchStoriesByBranchLink(branchLink));
    }
  }, [dispatch, branchLink]);

  useEffect(() => {
    // Вызов функции для создания групп сторис и обновление состояния
    if (storiesItems) {
      const groups = createStoriesGroups(storiesItems);
      setStoriesGroups(groups);
    }
  }, [storiesItems]);

  //const tableExists = useSelector((state) => state.tableUrl.exists); // Берем из слайса если такой столик? И передаем его в пропсы

  if (dishStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (dishStatus === 'failed') {
    navigate(`/notfoundpage`);
  }

  if (!branchUseDelivery) {
    return (
      <div className={` h-screen`}>
        <LogoHeader></LogoHeader>
        <Header pageName={pageName} />
        <Unavailible text={'Страница доставки не доступна'}></Unavailible>
      </div>
    );
  }

  const scrollToCategory = (category) => {
    const element = document.getElementById(`category-${category}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleCategoryClick = (category) => {
    const element = document.getElementById(`category-${category}`);
/*     console.log('~ element: ', element);
    console.log('~ category: ', category); */
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'start' });
      setActiveCategory(category);
    }
  };

  function createStoriesGroups(stories) {
    const groups = [];

    stories.forEach((story) => {
      if (story.display_location === 1 || story.display_location === 3) {
        groups.push(story);
      }
    });

    return groups;
  }

  // тариф только отзывы. Остальное отключаем
  if (subsOptions <= 1) {
    return <Unavailible text={'Страница недоступна'} />;
  }

  return (
    <>
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="btn-dp-1 fixed bottom-12 right-2  text-white rounded ">
        <CiCircleChevUp className="icon-dp-2 text-3xl text-slate-500" />
      </button>
      <div
        className="pb-20 main-page div-dp-3"
        style={{ backgroundColor: colors.colorMainBackground }}>
        <LogoHeader></LogoHeader>
        <Header pageName={pageName} bgColor={colors.colorTopMainMenu} />

        {/* СТОРИСЫ */}
        <div className="div-dp-4 pt-2 flex overflow-x-auto">
          <div className="div-dp-5 pl-1 stories-preview-container flex flex-row gap-2">
            {storiesGroups.map((story, index) => (
              <div
                className="div-dp-6"
                key={index}
                style={{
                  /* maxWidth: '120px', */
                  /* flexBasis: '120px', */
                  flexGrow: 1,
                }}>
                <StoryPreview
                  content={story.contents}
                  profileImage={story.profileImg}
                  onClick={() => setSelectedStories([story.contents])}
                  colorStoriesGroupBorder={colors.colorStoriesGroupBorder}
                  colorFontGroupStories={colors.colorFontGroupStories}
                  groupStoriesWidth={colors.groupStoriesWidth}
                  groupStoriesHeight={colors.groupStoriesHeight}
                  groupStoriesBorderRadius={colors.groupStoriesBorderRadius}
                  groupStoriesBorderRadiusSize={colors.groupStoriesBorderRadiusSize}
                />
                <div
                  className="div-dp-7 story-title text-center"
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    color: colors.colorFontGroupStories,
                    fontFamily: colors.fontForTitleGroupStoies,
                    fontSize: `${colors.fontForTitleGroupStoriesSize}px`,
                    fontWeight: colors.fontForTitleGroupStoriesWeight,
                    fontStyle: colors.fontForTitleGroupStoriesStyle,
                  }}>
                  <span className="span-dp-8"> {story.title}</span>
                </div>
              </div>
            ))}
          </div>
          {selectedStories.length > 0 && (
            <StoriesViewer stories={selectedStories[0]} onClose={() => setSelectedStories([])} />
          )}
        </div>

        <SearchInput setSearchTerm={setSearchTerm} />
        {!isModalOpen && (
          <div
            className="sticky-menu"
            ref={menuRef}
            style={{ backgroundColor: colors.colorBackgroundHorizontalMenu }}>
            <ScrollMenu>
              {sortedCategories.map((category) => (
                <Link
                  key={category}
                  to={`category-${category}`}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={() => handleCategoryClick(category)}>
                  <div
                    className={`menu-item category-${category}`}
                    data-category={category}
                    style={{
                      backgroundColor: colors.colorCategoryHorizontal,
                      borderRadius: `${colors.horizCategoryBorderRadius}px`,
                      color: colors.colorFontCategoryHorizontal,
                      fontSize: `${colors.fontForHorizontalCategorySize}px`,
                      fontFamily: colors.fontForHorizontalCategory,
                      fontWeight: colors.fontForHorizontalCategoryWeight,
                      fontStyle: colors.fontForHorizontalCategoryStyle,
                      borderColor: colors.colorBorderCategoryHorizontal,
                      borderWidth: `${colors.horizCategoryBorderSize}px`,
                    }}>
                    <span className="menu-item-span menu-item-span-dp">{category}</span>
                  </div>
                </Link>
              ))}
            </ScrollMenu>
          </div>
        )}

        {/*        <HorizontalMenu
          categories={sortedCategories}
          onCategoryClick={handleCategoryClick}
          activeCategory={activeCategory}
          ref={menuRef}
        /> */}
        {/*   <div className="text-center font-bold">Доставка</div> */}
        <div
          className="div-dp-9"
          style={{
            color: colors.colorfontDeliveryMenu,
            fontFamily: colors.fontDeliveryMenu,
            fontSize: `${colors.fontDeliveryMenuSize}px`,
            fontWeight: colors.fontDeliveryMenuWeight,
            fontStyle: colors.fontDeliveryMenuStyle,
          }}>
          <div>
            <p className="p-dp-10 px-4 py-1">{deliveryTerms}</p>
          </div>
          <div>
            <span>
              {' '}
              {deliveryPhone && <p className="px-4 py-1">Тел. доставки: {deliveryPhone}</p>}
            </span>
          </div>
        </div>

        {/* Горизонтальоне меню */}
        <div className="content horizontal-menu">
          <div className="div-dp-11">
            {sortedCategories.map((category) => {
              const filteredDishes = groupedDishes[category].dishes
                .sort((a, b) => a.position - b.position)
                .filter((dish) => {
                  const searchTermLower = searchTerm?.toLowerCase() || ''; // Проверка searchTerm на null и преобразование к нижнему регистру
                  return (
                    dish.menuoption !== 3 &&
                    dish.menuoption !== 4 &&
                    (dish.title?.toLowerCase().includes(searchTermLower) ||
                      category.toLowerCase().includes(searchTermLower) ||
                      dish.description?.toLowerCase().includes(searchTermLower) ||
                      (dish.tags &&
                        dish.tags.some((tag) =>
                          tag.description?.toLowerCase().includes(searchTermLower),
                        )))
                  );
                });
              // Извлечь описание категории из первого блюда
              const categoryDescription = groupedDishes[category].dishes[0]?.category_description;

              // Если в категории нет блюд после фильтрации, пропустить отображение категории
              if (
                filteredDishes.length === 0 &&
                !category.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return null;
              }

              return (
                <Element name={`category-${category}`} key={category}>
                  <div
                    style={{ backgroundColor: colors.colorDishBackground }}
                    className="div-dp-12 dish-back bg-slate-100 pb-2"
                    key={category}
                    id={`category-${category}`}>
                    <div
                      style={{
                        backgroundColor: colors.colorCategoryMenu,
                        color: colors.colorFontCategory,
                        fontFamily: colors.fontForCategory,
                        fontSize: `${colors.fontForCategorySize}px`,
                        fontWeight: `${colors.fontForCategoryWeight}`,
                        fontStyle: `${colors.fontForCategoryStyle}`,
                      }}
                      className="div-dp-13  category-menu text-xl text-center  bg-slate-100  py-1">
                      <span> {category}</span>
                    </div>
                    {categoryDescription && (
                      <div
                        style={{
                          backgroundColor: colors.colorCategoryDescriptionMenu,
                          color: colors.colorFontCategoryDescription,
                          fontFamily: colors.fontForCategoryDescription,
                          fontSize: `${colors.fontForCategoryDescriptionSize}px`,
                          fontWeight: `${colors.fontForCategoryDescriptionWeight}`,
                          fontStyle: `${colors.fontForCategoryDescriptionStyle}`,
                        }}
                        className="div-dp-14 category-desc text-sm text-center  bg-slate-100  py-1">
                        <span> {categoryDescription}</span>
                      </div>
                    )}
                    <div
                      className={
                        cardViewVariant === 0
                          ? 'flex flex-col mx-2'
                          : 'div-mainpage-15 grid mx-2 grid-cols-2 sm:grid-cols-3 md:grid-col-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-x-1'
                      }>
                      {filteredDishes.map((dish) => (
                        <Card
                          cardViewVariant={cardViewVariant}
                          key={dish.id}
                          {...dish}
                          setShowFooter={setShowFooter}
                          onAddToCart={handleAddToCart}
                          onRemoveFromCart={handleRemoveFromCart}
                          currentQuantity={cart.find((item) => item.id === dish.id)?.quantity || 0}
                          isDelivery={isDelivery}
                          //branchLink={branchLink}
                          bgColor={colors.colorDishCard}
                          colorFontTitle={colors.colorFontDishTitle}
                          colorOrderButtonPlus={colors.colorOrderButtonPlus}
                          colorFontDishDescription={colors.colorFontDishDescription}
                          colorFontDishPrice={colors.colorFontDishPrice}
                          colorFontDishOutput={colors.colorFontDishOutput}
                          colorDishCardBorder={colors.colorDishCardBorder}
                          colorFontDishOrder={colors.colorFontDishOrder}
                          colorFontDishOrderCount={colors.colorFontDishOrderCount}
                          fontForDishCardTitle={colors.fontForDishCardTitle}
                          fontForDishCardDescription={colors.fontForDishCardDescription}
                          fontForDishCardOutput={colors.fontForDishCardOutput}
                          fontForDishCardPrice={colors.fontForDishCardPrice}
                          fontForDishCardTitleSize={colors.fontForDishCardTitleSize}
                          fontForDishCardTitleWeight={colors.fontForDishCardTitleWeight}
                          fontForDishCardTitleStyle={colors.fontForDishCardTitleStyle}
                          fontForDishCardDescriptionSize={colors.fontForDishCardDescriptionSize}
                          fontForDishCardDescriptionWeight={colors.fontForDishCardDescriptionWeight}
                          fontForDishCardDescriptionStyle={colors.fontForDishCardDescriptionStyle}
                          fontForDishCardOutputSize={colors.fontForDishCardOutputSize}
                          fontForDishCardOutputWeight={colors.fontForDishCardOutputWeight}
                          fontForDishCardOutputStyle={colors.fontForDishCardOutputStyle}
                          fontForDishCardPriceSize={colors.fontForDishCardPriceSize}
                          fontForDishCardPriceWeight={colors.fontForDishCardPriceWeight}
                          fontForDishCardPriceStyle={colors.fontForDishCardPriceStyle}
                          dishCardBorderRadius={colors.dishCardBorderRadius}
                          dishCardBorderSize={colors.dishCardBorderSize}
                          colorDishCardButtonBorder={colors.colorDishCardButtonBorder}
                          dishCardButtonBorderSize={colors.dishCardButtonBorderSize}
                          dishCardButtonBorderRadius={colors.dishCardButtonBorderRadius}
                        />
                      ))}
                    </div>
                  </div>
                </Element>
              );
            })}

            <style jsx>{`
              .sticky-menu {
                position: sticky;
                top: 0;
                background-color: white;
                z-index: 1000;
                display: flex;
                overflow-x: auto;
                margin-top: 10px;
              }
              .menu-item {
                padding: 5px 5px;
                padding-bottom: 8px;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 5px;
                margin-left: 2px;
                cursor: pointer;
                white-space: nowrap; /* Запрещаем перенос текста */
                overflow: hidden; /* Скрываем переполнение */
                text-overflow: ellipsis; /* Добавляем троеточие для длинного текста */
              }
              .menu-item.active {
                background-color: #ddd;
              }
              .content {
                padding-top: 10px;
              }
            `}</style>
          </div>
        </div>

        {showFooter && (
          <CartFooterDelivery
            className="mt-10"
            handleClearCart={handleClearCart}
            colorOrderDiv={colors.colorOrderDiv}
            colorOrderDivButton={colors.colorOrderDivButton}
            colorOrderDivButtonBorder={colors.colorOrderDivButtonBorder}
            colorOrderDivFontColor={colors.colorOrderDivFontColor}
            colorOrderDivFontColorButton={colors.colorOrderDivFontColorButton}
            fontForOrderDiv={colors.fontForOrderDiv}
            allButtonsBorderRadius={colors.allButtonsBorderRadius}
            colorFontInputFeedback={colors.colorFontInputFeedback}
          />
        )}
      </div>
    </>
  );
};

export default DeliveryPage;
