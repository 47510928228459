import React from 'react';
import { selectPlatforms } from '../store/slices/reviewPlatformSlice';
import { useSelector } from 'react-redux';
import { selectBranchOptions } from '../store/slices/branchOptionsSlice';
import { selectColorMainBackground } from '../store/slices/branchSlice';

const Platforms = ({ onPlatformClick, reviewText }) => {
  const platforms = useSelector(selectPlatforms);
  const branchOptions = useSelector(selectBranchOptions);
  const positiveText = branchOptions.positive_feedback_text;
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const handleClick = (platformName) => {
    if (onPlatformClick) {
      onPlatformClick(platformName);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(reviewText)
      .then(() => {})
      .catch((err) => {
        console.error('Ошибка при копировании: ', err);
      });
  };

  return (
    <>
      <div className='div-platforms-1'>
        <div
          style={{
            color: colors.colorFontFeedback,
            fontFamily: colors.fontForFeedback,
            fontSize: `${colors.fontForFeedbackSize}px`,
            fontWeight: `${colors.fontForFeedbackWeight}`,
            fontStyle: `${colors.fontForFeedbackStyle}`,
          }}
          className="div-platforms-2 div-paltforms-text p-3 text-xl">
          <span className='span-paltforms-text'> {positiveText}</span>
        </div>

        <div className="div-platforms-feedback-3 flex flex-col items-center w-full">
          {reviewText && (
            <div
              style={{ color: colors.colorFontFeedback }}
              className="div-platforms-feedback-4 pt-6 pl-6 pr-6 w-full max-w-2xl mx-auto">
              <p  style={{ fontFamily: colors.fontForFeedback }} className="p-platforms-feedback-1 text-left">
                Ваш отзыв:
              </p>
              <p className="div-platforms-reviewText text-left">{reviewText}</p>
            </div>
          )}
          {reviewText && (
            <button
              style={{
                backgroundColor: colors.colorButtonFeedback,
                borderColor: colors.colorButtonBorderFeedback,
                borderWidth: '1px',
                color: colors.colorButtonTextFeedback,
              }}
              className="btn btn-copy-platforms reviewText mt-4 mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={copyToClipboard}>
              Скопировать
            </button>
          )}
        </div>

        <div className="div-platforms-5 flex flex-col items-center">
          {platforms.map((platform, index) => (
            <div key={index} className="div-platforms-6 mb-5">
              <a href={platform.url} target="_blank" rel="noopener noreferrer" className='a-platforms-6'>
                {platform.img === '' ? (
                  <div className="div-platforms-name-7 border-2">
                    <p className="p-platforms-name-8 p-4 text-blue-700">{platform.name}</p>
                  </div>
                ) : (
                  <div className='div-platforms-img-8'>
                    <img
                      src={`/assets/feedback/${platform.img}`}
                      alt={platform.name}
                      className="img-platforms-9 w-100 h-20"
                      onClick={() => handleClick(platform.name)}
                    />
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Platforms;
