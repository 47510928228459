import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearCart,
  selectCart,
  selectTotalPrice,
  selectTotalQuantity,
} from '../store/slices/cartSlice';
import { ThemeContext } from '../contexts/ThemeContext'; // тема
import { RiDeleteBin6Line } from 'react-icons/ri';
import PropTypes from 'prop-types';
import {
  selectCartDelivery,
  selectTotalPriceDelivery,
  selectTotalQuantityDelivery,
} from '../store/slices/cartDeliverySlice';
import {
  selectChatiIdDelivery,
  selectChatiIdOrder,
  selectColorMainBackground,
} from '../store/slices/branchSlice';
import axios from 'axios';
import { selectTableName } from '../store/slices/tableUrlSlice';
import { Link, useLocation } from 'react-router-dom';
import { selectBranchLinkGlobal } from '../store/slices/branchLinkGlobal';

const CartFooterDelivery = ({
  handleClearCart,
  colorOrderDiv,
  colorOrderDivButton,
  colorOrderDivButtonBorder,
  colorOrderDivFontColor,
  colorOrderDivFontColorButton,
  fontForOrderDiv,
  allButtonsBorderRadius, 
  colorFontInputFeedback
}) => {
  const branchLinkGlobal = useSelector(selectBranchLinkGlobal);
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const totalQuantity = useSelector((state) => {
    const slice = selectTotalQuantityDelivery(state);
    return slice;
  });

  const totalPrice = useSelector((state) => {
    const slice = selectTotalPriceDelivery(state);
    return slice;
  });

  const { theme } = useContext(ThemeContext);

  const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
  const chatIdOrder = useSelector(selectChatiIdOrder);
  const chatIdDelivery = useSelector(selectChatiIdDelivery);
  let tableName = useSelector(selectTableName);

  function sendMsgGood(order) {
    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

    let sendText = '🚚 Заказ на доставку' + '\n\n' + order;

    return axios
      .post(endpoint, {
        chat_id: chatIdDelivery,
        text: sendText,
      })
      .then((response) => {
        // console.log('~ Message sent to Telegram:', response.data);
        handleClearCart();
      })
      .catch((error) => {
        console.error('Error sending message to Telegram:', error);
      });
  }

  let cartLocal = useSelector(selectCart);
  let cartDelivery = useSelector(selectCartDelivery);

  function sendOrder() {
    let order = cartDelivery.map((item) => `${item.title}: ${item.quantity}`).join('\n');

    sendMsgGood(order);
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableUrl = queryParams.get('t');

  return (
    <div
      style={{ backgroundColor: colorOrderDiv, fontFamily: fontForOrderDiv }}
      className={`div-cart-delivery-1 cart-footer pb-1 shadow border-t flex flex-row justify-between items-center fixed bottom-0 h-11 w-screen  pl-1 pt-2 `}>
      <div className="div-cart-delivery-2 ml-2">
        <RiDeleteBin6Line className='delete-icon' style={{ color: colorOrderDivFontColor }} onClick={handleClearCart} />
      </div>
      <div className='div-cart-delivery-quantity' style={{color: colorOrderDivFontColor, fontSize: `${colors.fontForOrderDivSize}px`, fontWeight: `${colors.fontForOrderDivWeight}`, fontStyle: `${colors.fontForOrderDivStyle}`}}> <span className='div-cart-delivery-quantity'> Кол-во: {totalQuantity}</span></div>
      <div style={{color: colorOrderDivFontColor, fontSize: `${colors.fontForOrderDivSize}px`, fontWeight: `${colors.fontForOrderDivWeight}`, fontStyle: `${colors.fontForOrderDivStyle}`}} className="div-cart-delivery-summ pl-6">
        <span className='span-cart-delivery-summ'> Сумма: {parseFloat(totalPrice.toFixed(2))}</span>
      </div>

      <div >
        <Link to={`/${branchLinkGlobal}/orderdelivery/${tableUrl ? `?t=${tableUrl}` : ''}`}>
          <button
            style={{
              backgroundColor: colorOrderDivButton,
              borderColor: colorOrderDivButtonBorder,
              color: colorOrderDivFontColorButton,
              borderRadius: `${allButtonsBorderRadius}px`
            }}
            className="btn btn-cart-delivery-submit border shadow-md rounded-lg ml-4 p-1 mt-1 mb-2 mr-2  ">
            Оформить
          </button>
        </Link>
      </div>
    </div>
  );
};

CartFooterDelivery.propTypes = {
  handleClearCart: PropTypes.func.isRequired,
};

export default CartFooterDelivery;
