import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPayments = createAsyncThunk(
    'tips/fetchPayments',
    async (branchLink) => {
        // console.log('~ 🍡 fetchPayments');
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/payments-app`, {
            params: {
                branchLink: branchLink,
            },
        });
        return response.data;
    }
);


const paymentSlice = createSlice({
    name: 'payments',
    initialState: {
        paymentLink: '',
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPayments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPayments.fulfilled, (state, action) => {
                state.loading = false;
                // console.log('~ 🎆 action.payload fetchPayments: ', action.payload);
                state.paymentLink = action.payload.link_sbp;
            })
            .addCase(fetchPayments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            ;
    },
});

export const selectPayments = (state) => state.payments.paymentLink;


export default paymentSlice.reducer;