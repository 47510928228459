import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearCart,
  selectCart,
  selectTotalPrice,
  selectTotalQuantity,
} from '../store/slices/cartSlice';
import { ThemeContext } from '../contexts/ThemeContext'; // тема
import { RiDeleteBin6Line } from 'react-icons/ri';
import PropTypes from 'prop-types';
import {
  selectCartDelivery,
  selectTotalPriceDelivery,
  selectTotalQuantityDelivery,
} from '../store/slices/cartDeliverySlice';
import {
  selectChatiIdDelivery,
  selectChatiIdOrder,
  selectColorMainBackground,
} from '../store/slices/branchSlice';
import axios from 'axios';
import { selectTableName } from '../store/slices/tableUrlSlice';
import OrderL from '../assets/icons/bell-l.svg';
import OrderD from '../assets/icons/order-d.svg';
import ShowOrder from './ShowOrder';

const CartFooter = ({
  handleClearCart,
  isDelivery,
  colorOrderDiv,
  colorOrderDivButton,
  colorOrderDivButtonBorder,
  colorOrderDivFontColor,
  colorOrderDivFontColorButton,
  fontForOrderDiv,
  colorMainBackground,
  colorFontBranchName,
  colorSearch,
  allButtonsBorderRadius, 
  allInputsBorderRadius,
  
}) => {
  const totalQuantity = useSelector((state) => {
    const slice = isDelivery ? selectTotalQuantityDelivery(state) : selectTotalQuantity(state);
    return slice;
  });

  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const totalPrice = useSelector((state) => {
    const slice = isDelivery ? selectTotalPriceDelivery(state) : selectTotalPrice(state);
    return slice;
  });

  const { theme } = useContext(ThemeContext);

  const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
  const chatIdOrder = useSelector(selectChatiIdOrder);
  //const chatIdDelivery = useSelector(selectChatiIdDelivery);
  let tableName = useSelector(selectTableName);

  function sendMsgGood(order, isDelivery) {
    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

    let sendText;
    let chatId = chatIdOrder;

    sendText = '📍 Заказ \n' + tableName + '\n\n' + order;

    return axios
      .post(endpoint, {
        chat_id: chatId,
        text: sendText,
      })
      .then((response) => {
        // console.log('~ Message sent to Telegram:', response.data);
        handleClearCart();
      })
      .catch((error) => {
        console.error('~ Error sending message to Telegram:', error);
      });
  }

  let cartLocal = useSelector(selectCart);
  let cartDelivery = useSelector(selectCartDelivery);

  if (isDelivery) {
    // console.log('~ is Delivery order = ', cartDelivery);
  }

  function sendOrder() {
    let order;
    if (isDelivery) {
      order = cartDelivery.map((item) => `${item.title}: ${item.quantity}`).join('\n');
    } else {
      order = cartLocal.map((item) => `${item.title}: ${item.quantity}`).join('\n');
    }

    sendMsgGood(order, isDelivery);
  }

  const orderIcon = theme === 'light' ? OrderD : OrderL;

  const [showOrder, setShowOrder] = useState(false); // Состояние для управления видимостью модального окна

  const toggleShowOrder = () => {
    setShowOrder(!showOrder);
  };


  return (
    <div
      style={{ backgroundColor: colorOrderDiv, fontFamily: fontForOrderDiv }}
      className={`div-cart-footer-1 cart-footer pb-1  shadow border-t flex flex-row justify-between items-center fixed bottom-0 h-11 w-screen  pl-1 pt-2 `}>
      {showOrder && (
        <ShowOrder
          onClose={toggleShowOrder}
          isDelivery={isDelivery}
          colorMainBackground={colorMainBackground}
          colorFontBranchName={colorFontBranchName}
          colorSearch={colorSearch}
          allInputsBorderRadius={allInputsBorderRadius}
          allButtonsBorderRadius={allButtonsBorderRadius}
          colorOrderDivButton={colorOrderDivButton}
          colorOrderDivFontColorButton={colorOrderDivFontColorButton}
          colorOrderDivButtonBorder={colorOrderDivButtonBorder}
          colorFontSearchInput={colors.colorFontSearchInput}
        />
      )}

      <div style={{ color: colorOrderDivFontColor }} className="div-cart-delete-btn ml-2">
        <RiDeleteBin6Line onClick={handleClearCart} className='cart-delete-icon' />
      </div>
      <div
      className='div-quanity'
        style={{
          color: colorOrderDivFontColor,
          fontSize: `${colors.fontForOrderDivSize}px`,
          fontWeight: `${colors.fontForOrderDivWeight}`,
          fontStyle: `${colors.fontForOrderDivStyle}`,
        }}>
        <span className='div-cart-span-quantity'> Кол-во: {totalQuantity}</span>
      </div>
      <div
        style={{
          color: colorOrderDivFontColor,
          fontSize: `${colors.fontForOrderDivSize}px`,
          fontWeight: `${colors.fontForOrderDivWeight}`,
          fontStyle: `${colors.fontForOrderDivStyle}`,
        }}
        className="div-cart-summ pl-6">
        <span className='span-cart-summ'> Сумма: {parseFloat(totalPrice.toFixed(2))}</span>
      </div>
      {/*   <div>
        <img src={orderIcon} alt="orders" className="w-5" onClick={toggleShowOrder}/>
      </div> */}
      <div>
        <button
          style={{
            backgroundColor: colorOrderDivButton,
            borderColor: colorOrderDivButtonBorder,
            color: colorOrderDivFontColorButton,
            borderRadius: `${allButtonsBorderRadius}px`

          }}
          className="btn btn-cart-order  border shadow-md  ml-1 p-1 mt-1 mb-2 mr-2"
          onClick={toggleShowOrder}>
          Заказ
        </button>
      </div>
    </div>
  );
};

CartFooter.propTypes = {
  handleClearCart: PropTypes.func.isRequired,
  isDelivery: PropTypes.bool,
};

export default CartFooter;
