import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext'; // тема
import Header from '../Components/Header';
import LogoHeader from '../Components/LogoHeader';
import { useSelector } from 'react-redux';
import { selectColorMainBackground, selectUseFeedback } from '../store/slices/branchSlice';
import Unavailible from '../Components/Unavailable';

const FeedbackThanksPage = () => {
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  const branchUseFeedback = useSelector(selectUseFeedback);
  if (!branchUseFeedback) {
    return (
      <div className={` h-screen`}>
        <LogoHeader></LogoHeader>
        <Header />
        <Unavailible></Unavailible>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: colors.colorMainBackground }} className={`div-fbtp-1 main-page h-screen`}>
      <LogoHeader></LogoHeader>
      <Header />

      <div
        style={{
          color: colors.colorFontFeedback,
          fontFamily: colors.fontForFeedback,
          fontSize: `${colors.fontForFeedbackSize}px`,
          fontWeight: `${colors.fontForFeedbackWeight}`,
          fontStyle: `${colors.fontForFeedbackStyle}`,
        }}
        className={`div-fbtp-2  flex justify-center pt-10 `}>
        <div>Спасибо за отзыв!</div>
      </div>
    </div>
  );
};

export default FeedbackThanksPage;
