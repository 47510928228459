import React, { useState } from 'react';
/* import { ThemeContext } from '../contexts/ThemeContext'; // тема */
/* import BellL from '../assets/icons/bell-l.svg';
import BellD from '../assets/icons/bell-d.svg';
import OrderL from '../assets/icons/order-l.svg';
import OrderD from '../assets/icons/order-d.svg'; */
// import { LuBellRing } from 'react-icons/lu';
import { PiCallBellLight } from 'react-icons/pi';

import { LuSearch } from 'react-icons/lu';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectBranchInfo,
  selectCardViewVariant,
  selectChatiIdOrder,
  selectColorMainBackground,
  selectUseCallWaiter,
} from '../store/slices/branchSlice';
import axios from 'axios';
import { selectTableName } from '../store/slices/tableUrlSlice';
import CustomAlert from './CustomAlert';
import { CiBoxList } from 'react-icons/ci';
import { CiGrid41 } from 'react-icons/ci';
import { setCardViewVariant } from '../store/slices/viewVariantSlice';

const SearchInput = ({ setSearchTerm }) => {
  const dispatch = useDispatch();

  const tableExists = useSelector((state) => state.tableUrl.exists);
  const cardViewVariant = useSelector((state) => state.viewVariant.cardViewVariant);

  const callwaiter = useSelector(selectUseCallWaiter);

  /*   const bellIcon = theme === 'light' ? BellD : BellL;
  const orderIcon = theme === 'light' ? OrderD : OrderL; */
  /*   const bellIcon = BellD;
  const orderIcon = OrderD; */

  const chatIdOrder = useSelector(selectChatiIdOrder);
  const tableName = useSelector(selectTableName);
  /*   const branchName = useSelector(selectBranchName);
  const branchCity = useSelector(selectBranchCity);
  const branchAddress = useSelector(selectBranchAddress); */

  const branchInfo = useSelector(selectBranchInfo);
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  function sendTelegram(action) {
    const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;
    let sendText;
    if (action === 'waiter') {
      sendText = branchInfo + '\n\n' + '🖐️ Вызов официанта \n\n' + tableName;
    }
    if (action === 'bill') {
      sendText = branchInfo + '\n\n' + '📃 Счет пожалуйста \n\n' + tableName;
    }

    return axios
      .post(endpoint, {
        chat_id: chatIdOrder,
        text: sendText,
      })
      .then((response) => {
        // console.log('~ Message sent to Telegram:', response.data);
      })
      .catch((error) => {
        console.error('Error sending message to Telegram:', error);
      });
  }

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const handleConfirm = (action) => {
    // Здесь вы можете отправить сообщение в Телеграм в зависимости от выбранного действия
    // console.log(`~ Пользователь выбрал: ${action}`);
    sendTelegram(action);
  };

  return (
    <div className={`div-search-1 py-1 px-1  `}>
      <div className="div-search-2 flex flex-row items-center">
        <div className="div-search-3 w-full ">
          <div
            style={{
              backgroundColor: colors.colorSearch,
              borderColor: colors.colorSearchBorder, //
              borderRadius: `${colors.allInputsBorderRadius}px`,
              borderWidth: `${colors.searchBorderSize}px`,
            }}
            className="div-search-4 flex items-center w-full rounded border border-solid border-neutral-300 bg-transparent">
            <LuSearch
              className="div-search-LuSearch-5 mx-3 text-neutral-700"
              style={{ color: colors.colorFontSearchPlaceholder }}
            />

            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              type="search"
              className="input-search-6 flex-grow rounded  px-3 py-[0.25rem] text-base font-normal leading-[1.6]  outline-none transition duration-200 ease-in-out focus:border-primary  focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
              style={{ color: colors.colorFontSearchInput, backgroundColor: colors.colorSearch }}
            />
          </div>
        </div>
        {tableExists && (
          <div className="div-search-7 ">
            {!!callwaiter && (
              <div className="div-search-8 icon-bell ml-2 ">
                <PiCallBellLight
                  className="btn-call-9 text-2xl"
                  style={{ color: colors.colorWaiterCallIconButton }}
                  onClick={handleOpenAlert}
                />
              </div>
            )} 
            <CustomAlert
              isOpen={isAlertOpen}
              onClose={handleCloseAlert}
              onConfirm={handleConfirm}
              colorMainBackground={colors.colorMainBackground}
              allButtonsBorderRadius={colors.allButtonsBorderRadius}
              colorFontBranchName={colors.colorFontBranchName}
            />
          </div>
        )}
        <div className="div-view-switch mr-1 ml-2">
          {cardViewVariant === 0 ? (
            <CiGrid41
              onClick={() => dispatch(setCardViewVariant(1))}
              style={{ color: colors.colorWaiterCallIconButton }}
              className="icon-grid icon-switch text-2xl icon-grid"
            />
          ) : (
            <CiBoxList
              onClick={() => dispatch(setCardViewVariant(0))}
              style={{ color: colors.colorWaiterCallIconButton }}
              className="icon-list icon-switch text-2xl"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
