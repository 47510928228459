import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import LogoHeader from '../Components/LogoHeader';
import Header from '../Components/Header';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import {
  fetchBranch,
  selectBranchId,
  selectColorMainBackground,
  selectSubscription_options,
  selectSubscription_state,
  selectUseFeedback,
  selectUsePayment,
} from '../store/slices/branchSlice';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import Unavailible from '../Components/Unavailable';
import ExpiredSubscription from '../Components/ExpiredSubscription';
import { fetchPayments, selectPayments } from '../store/slices/paymentSlice';

const PaymentPage = () => {
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';
  const subsOptions = useSelector(selectSubscription_options);

  const subsState = useSelector(selectSubscription_state);
  const paymentLink = useSelector(selectPayments);

  const [isLoading, setIsLoading] = useState(true); // Добавляем стейт для отслеживания загрузки
  const navigate = useNavigate();
  const { branchLink, tableUrl } = useParams();
  const dispatch = useDispatch();

  dispatch(setBranchLinkGlobal(branchLink));

  const branchId = useSelector(selectBranchId) || null;
  // console.log('~ ++---- TipsPage branchId =', branchId);

  useEffect(() => {
    if (branchId === null && branchLink) {
      setIsLoading(true);
      dispatch(fetchBranch(branchLink))
        .then((action) => {
          if (action.payload) {
            setIsLoading(false);
          } else {
            navigate(`/notfoundpage`);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }

    if (paymentLink === '') {
      dispatch(fetchPayments(branchLink));
    }
  }, [branchId, branchLink, dispatch]);

  const pageName = 'payment';

  const branchUsePayment = useSelector(selectUsePayment);
  if (!branchUsePayment) {
    return (
      <div className={` h-screen main-page payment-page`}>
        <LogoHeader></LogoHeader>
        <Header pageName={pageName} />
        <Unavailible text={'Страница оплаты не доступна'}></Unavailible>
      </div>
    );
  }

  if (isLoading) {
    return <div>Загрузка...</div>; // Отображаем индикатор загрузки
  }

  // подписка отключена
  if (subsState === 0) {
    return <ExpiredSubscription />;
  }

  // тариф только отзывы. Остальное отключаем
  if (subsOptions <= 1) {
    return <Unavailible text={'Страница недоступна'} />;
  }

  return (
    <div className={` h-screen main-page payment-page`} style={{ backgroundColor: colors.colorMainBackground }}>
      <div>
        <LogoHeader></LogoHeader>
        <Header pageName={pageName} bgColor={colors.colorTopMainMenu} />
      </div>
      <div className='flex justify-center mt-10'>
        <button
          className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-medium py-2 px-4 rounded flex items-center"
          onClick={() => window.open(paymentLink, '_blank')}>
          <div className="flex flex-row">
            <div>
              <img src="/sbp.png" alt="СБП" className=" w-7 mr-2" />
            </div>
            <div className="flex flex-col justify-center align-top">Оплатить по СБП </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default PaymentPage;
