// src\store\slices\branchOptionsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

 // Асинхронная функция для получения настроек для филиала по branchLink
 export const fetchBranchOptionsByBranchId = createAsyncThunk(
    'branchOptions/fetchByBranchId',
    async (branchId) => {
      // // console.log('~ вызван fetchBranchOptionsByBranchId');
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/branchOptions/${branchId}`);
      // //console.log('~ fetchBranchOptionsByBranchId response.data server = ', response.data);
      return response.data;
    }
  );  
  
  const initialState = [];


  const branchOptionsSlice = createSlice({
    name: 'branchOptions',
    initialState,
    reducers: {
      // Можно добавить другие редьюсеры, если они понадобятся
    },
    extraReducers: {
       [fetchBranchOptionsByBranchId.fulfilled]: (state, action) => {
        return action.payload;
      } 
    }
  });
  
  export const selectBranchOptions = (state) => state.branchOptions;
  export default branchOptionsSlice.reducer;
