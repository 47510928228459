// reviewPlatformSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Асинхронная функция для получения платформ отзывов по branchLink
export const fetchPlatformsByBranchId = createAsyncThunk(
  'reviewPlatforms/fetchByBranchId',
  async (branchId) => {
    // console.log('~ вызван fetchPlatformsByBranchId');
    const response = await axios.get(`${process.env.REACT_APP_URL}/api/reviewplatforms/${branchId}`);
    return response.data;
  }
);


const initialState = [];

const reviewPlatformSlice = createSlice({
  name: 'reviewPlatforms',
  initialState,
  reducers: {
    // Можно добавить другие редьюсеры, если они понадобятся
  },
  extraReducers: {
    [fetchPlatformsByBranchId.fulfilled]: (state, action) => {
      return action.payload;
    }
  }
});

export const selectPlatforms = (state) => state.platforms;


export default reviewPlatformSlice.reducer;
