import React, { useContext, useEffect, useState } from 'react';
import ThemeToggle from './ThemeToggle';
import { ThemeContext } from '../contexts/ThemeContext';
import { useSelector } from 'react-redux';
import { RxDropdownMenu } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import { selectBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import { selectColorMainBackground } from '../store/slices/branchSlice';

const LogoHeader = () => {
  let branchName = useSelector((state) => state.branch.name);
  let branchCity = useSelector((state) => state.branch.city);
  let branchAddress = useSelector((state) => state.branch.address);
  let branchLogoUrl = useSelector((state) => state.branch.logo_url);
  const branchLinkGlobal = useSelector(selectBranchLinkGlobal);
  const queryParams = new URLSearchParams(window.location.search);
  const tableUrl = queryParams.get('t');
  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  // если в редаксе пусто, (пользователь обновил страницу), то берем данные из куков
  /*   if (branchName === '') {
    let branchObj = localStorage.getItem('branch');
    branchObj = JSON.parse(branchObj); // ссылка из local stora.getDate()
    branchName = branchObj.name;
    //-----------------
    branchCity = branchObj.city;
    branchAddress = branchObj.address;
    branchLogoUrl = branchObj.logo_url;
  }
 */
  useEffect(() => {}, [branchName]);

  return (
    <>
      {/* главный контейнер */}
      <div className={`div-logo-header-1 main-div flex flex-row items-start mb-1   px-1  `}>
        {/* логотип */}
        <div className="div-logo-header-2 main-div-2 flex-shrink-0 ">
          <Link to={`/${branchLinkGlobal}${tableUrl ? `?t=${tableUrl}` : ''}`}>
            <img
              className="img-logo-header logo-img h-12 py-1"
              src={`${process.env.REACT_APP_URL}/${branchLogoUrl}`}
              alt=""
            />
          </Link>
        </div>

        {/* название и адрес */}
        <div className="div-name-address flex-grow ">
          <div
            className="div-address-2 branch-name text-base font-bold px-3"
            style={{ color: colors.colorFontBranchName, fontFamily: colors.fontForTitle, fontSize: `${colors.fontForTitleSize}px`, fontWeight: `${colors.fontForTitleWeight}`, fontStyle: `${colors.fontForTitleStyle}` }}>
           <span> {branchName}</span>
          </div>
          <div
            className="div-address branch-address text-sm  px-3 "
            style={{ color: colors.colorFontBranchAddress, fontFamily: colors.fontForAdress, fontSize: `${colors.fontForAdressSize}px`, fontWeight: `${colors.fontForAdressWeight}`, fontStyle: `${colors.fontForAdressStyle}` }}>
           <span> {branchCity} , {branchAddress}</span>
          </div>
        </div>
        {/* ссылка на сервис */}
        <div div-restolink className=" flex-shrink-0  ">
          <a className='' href="https://restolink.ru?utm_source=root_service" >
            {/* <div className="text-xs font-extralight">Сервис</div> */}
            <div className="service-title text-xs pt-1 font-extralight text-blue-700">RestoLink.ru</div>
          </a>
        </div>
      </div>
    </>
  );
};

export default LogoHeader;
