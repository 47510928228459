import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

import TopCard from './TopCard';
import ThemeToggle from './ThemeToggle';

const Header = ({ pageName, bgColor, colorFontMainMenu }) => {
  return (
    <div className={`div-header-1 w-full  flex items-center justify-center  `}>
      <div className="div-header-2 ">
        <TopCard pageName={pageName} bgColor={bgColor} />
      </div>
    </div>
  );
};

export default Header;
