import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: []
  },
  reducers: {
    addToCart: (state, action) => {
      /* console.log('🧦 + addToCart', state.items); */
      const index = state.items.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.items[index].quantity += 1;
      } else {
        state.items.push(action.payload);
      }
      /* console.log('New cart state after addToCart:', state.items); */
    },
    removeFromCart: (state, action) => {
      /* console.log('🧦 - removeFromCart', state.items); */
      const index = state.items.findIndex(item => item.id === action.payload.id);
      /* console.log('Item index:', index); */
      if (index !== -1) {
        if (state.items[index].quantity > 1) {
          state.items[index].quantity -= 1;
         /*  console.log('Item quantity decreased:', state.items[index].quantity); */
        } else {
          state.items.splice(index, 1);
         /*  console.log('Item removed from cart:', action.payload.id); */
        }
      }
     /*  console.log('New cart state after removeFromCart:', state.items); */
    },
    clearCart: (state) => {
      /* console.log('🧦 clearCart'); */
      state.items = [];
      /* console.log('Cart cleared:', state.items); */
    }
  }
});

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;

export const selectTotalQuantity = (state) => 
  state.cart.items.reduce((total, item) => total + item.quantity, 0);

export const selectTotalPrice = (state) => 
  state.cart.items.reduce((total, item) => total + item.price * item.quantity, 0);

export const selectCart = (state) => {
  /* console.log('Selecting cart: ', state.cart); */
  return state.cart ? state.cart.items : [];
};

export default cartSlice.reducer;
