import React from 'react';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
 import defaultAvatar from '../assets/avatar-default.svg';
 

const TipsRecipientCard = ({ fullname, photo_url, layout_id, bgColor, font, fontColor, borderRadius, borderSize, colorBorder }) => {
  const link = 'https://pay.cloudtips.ru/p/' + layout_id;

  console.log('~ fontColor: ', fontColor);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="block">
      <div className="card-recipient flex items-center justify-between w-full p-4 bg-white shadow-md rounded-lg mt-2"
      style={{backgroundColor: bgColor, fontFamily: font, color: fontColor, borderRadius: `${borderRadius}px`, borderWidth: `${borderSize}px`, borderColor: colorBorder,}}
      >
        <div className="card-recipient-2 flex items-center flex-grow">
          <div className="card-recipient-3 w-16 h-16 mr-4 rounded-full overflow-hidden">
            {photo_url ? (
              <img src={photo_url} alt="Recipient" className="object-cover w-full h-full" />
            ) : (
              <img src={defaultAvatar} alt="Default Avatar" className="object-cover w-full h-full" />
            )}
          </div>
          <div className="card-recipient-name flex-grow">
            <h3 className="card-recipient-h3 text-lg font-semibold" >{fullname}</h3>
          </div>
        </div>
        <div className="ml-4">
          <MdOutlineArrowForwardIos />
        </div>
      </div>
    </a>
  );
};

export default TipsRecipientCard;
