import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Асинхронная функция для проверки наличия записи
export const checkTableExists = createAsyncThunk(
  'tables/checkTableExistsStatus',
  async ({ tableId, branchId }) => {
    // console.log('~ Вызыван checkTableExists из слайса tablesSlice');
    const response = await axios.get(`${process.env.REACT_APP_URL}/api/checkTableExists`, {
      params: {
        link: tableId,
    
      }
    });
    return response.data;
  }
);

const tablesSlice = createSlice({
  name: 'tables',
  initialState: {
    exists: false,
    status: 'idle',
    error: null, 
    isLoading: true,
  },
  reducers: {
    checkTableStart: (state) => {
      state.isLoading = true;
    },
    checkTableSuccess: (state, action) => {
      state.exists = action.payload;
      state.isLoading = false;
    },
    checkTableFailure: (state) => {
      state.isLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkTableExists.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkTableExists.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Если запись найдена, устанавливаем exists в true
        state.exists = action.payload.exists;
        state.isLoading = false; 
      })
      .addCase(checkTableExists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      });
  }
});

export const selectTableExists = state => state.tables.exists;


export default tablesSlice.reducer;
