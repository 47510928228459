import { configureStore } from '@reduxjs/toolkit'
import cartReducer from './slices/cartSlice'
import branchReducer from './slices/branchSlice'
import dishReducer from './slices/dishesSlice'
import platformReducer from './slices/reviewPlatformSlice'
import branchOptionsReducer from './slices/branchOptionsSlice'
import tablesReducer from './slices/tablesSlice'
import tableUrlReducer from './slices/tableUrlSlice'
import cartDeliveryReducer from './slices/cartDeliverySlice';
import branchLinkGlobalReducer  from './slices/branchLinkGlobal'
import fontsReducer from './slices/fontsSlice';
import storiesReducer from './slices/storiesSlice';
import customCssReducer  from './slices/customCssSlice';
import viewVariantReducer from './slices/viewVariantSlice'; // Импорт нового редьюсера
import tipsReducer from './slices/tipsSlice'
import paymentsReducer from './slices/paymentSlice'


//import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';

/* const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['someReducer'] // опционально, если вы хотите сохранять только определенные редьюсеры
}; */

/* const persistedCartReducer = persistReducer({ ...persistConfig, key: 'cart' }, cartReducer);
const persistedCartDeliveryReducer = persistReducer({ ...persistConfig, key: 'cartDelivery' }, cartDeliveryReducer);
 */


const store = configureStore({
  reducer: {

    fonts: fontsReducer,
    cart: cartReducer,
    //cart: persistedCartReducer,
    branch: branchReducer,
    dishes: dishReducer,
    platforms: platformReducer,
    branchOptions: branchOptionsReducer,
    tables: tablesReducer,
    tableUrl: tableUrlReducer,
    cartDelivery: cartDeliveryReducer,
    //cartDelivery: persistedCartDeliveryReducer,
    branchLinkGlobal: branchLinkGlobalReducer,
    stories: storiesReducer,
    customCss: customCssReducer, 
    viewVariant: viewVariantReducer,
    tips : tipsReducer,
    payments : paymentsReducer,
    
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveToLocalStorageMiddleware),
})


function saveToLocalStorageMiddleware(storeAPI) {
  return (next) => (action) => {
    const result = next(action);
    const state = storeAPI.getState();
    //localStorage.setItem('cart', JSON.stringify(state.cart));
    return result;
  };
}

//export const persistor = persistStore(store);

export default store