import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    id: null,
    name: '',
    logo_url: '',
    address: '',
    phone: '',
    email: '',
    working_hours: '',
    social_links: '',
    description: '',
    user_id: null,
    city: '',
    link: '',
    subscription_state: null,
    subscription_options :null, // 0 - выкл, 1 - только отзывы, 2 - полная подписка 
};




export const fetchBranch = createAsyncThunk('branch/fetchBranch', async (branchLink, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/getBranch/${branchLink}`);
        //localStorage.setItem('branch', JSON.stringify(response.data));
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        setBranch: (state, action) => {
            return action.payload;
        },
        clearBranch: () => initialState,

    },
    extraReducers: (builder) => {
        builder.addCase(fetchBranch.fulfilled, (state, action) => {
            return action.payload;
        });
        builder.addCase(fetchBranch.rejected, (state, action) => {
            console.error(action.error);
        });
    },
});

export const selectGoToFeedback = (state) => state.branch.settings;
export const selectColorMainBackground = (state) => state.branch.settings;// цвет фона основной 




export const selectUseOrderLocal = (state) => state.branch.useOrderLocal;
export const selectUseCallWaiter = (state) => state.branch.callwaiter;
export const selectBranchInfo = (state) => state.branch.name + ', ' + state.branch.city + ', ' + state.branch.address;

export const selectBranchId = (state) => state.branch.id;
export const selectBranchOrderThanks = (state) => state.branch.orderthanks;

export const selectChatiIdOrder = (state) => state.branch.chat_id_order;
export const selectChatiIdDelivery = (state) => state.branch.chat_id_delivery;
export const selectChatiIdFeedback = (state) => state.branch.chat_id_feedback;

export const selectBranchAddress = (state) => state.branch.address;
export const selectBranchCity = (state) => state.branch.city;
export const selectBranchName = (state) => state.branch.name;
export const selectBranchLogoUrl = (state) => state.branch.logo_url;
export const selectFeedbackOptions = (state) => state.branch.feedback_options;

export const selectUseMenu = (state) => state.branch.usemenu;
export const selectUseFeedback = (state) => state.branch.usefeedback;
export const selectUsePayment = (state) => state.branch.usepayment;
export const selectUseTips = (state) => state.branch.usetips;
export const selectUseDelivery = (state) => state.branch.usedelivery;
export const selectDeliveryTerms = (state) => state.branch.deliveryterms;
export const selectDeliveryPhone = (state) => state.branch.phone_delivery;
export const selectSubscription_state = (state) => state.branch.subscription_state;
export const selectSubscription_options = (state) => state.branch.subscription_options;
export const textabovemenu = (state) => state.branch.textabovemenu;
export const selectUseOrderComment = (state) => state.branch.useordercomment;

export const { setBranch, clearBranch } = branchSlice.actions;

export default branchSlice.reducer;
